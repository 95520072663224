// style the non popup controls
kui-daterange {
    .selected-values {
        display: flex;

        &__dates {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        &__icon {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }

        &__icon:not(.input-group-addon) {
            margin-left: 0.5rem;
        }
    }
}

// style the popup content
.kui-daterange-popup {

    .dropdown-menu {
        max-height: unset;
        max-width: unset;
    }

    .date-selector {

        &__panel {
            width: auto;
            height: auto;
            display: grid;
            column-gap: var(--padding);
            padding: var(--padding);

            .start-calendar {
                grid-column-start: 1;
                grid-row-start: 2;
            }

            .end-calendar {
                grid-column-start: 2;
                grid-row-start: 2;
            }

            .time-periods {
                grid-row: span 2 / span 2;
                grid-column-start: 3;
                grid-row-start: 1;
            }

            .confirm {
                margin-top: var(--padding);
                grid-column: 1/4;
                justify-self: center;
                align-self: center;
                width: 15%;
            }
        }

        &__date-display {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__label {
                width: 100%;
                margin: 0;
            }

            &__value {
                margin-bottom: var(--padding);
            }
        }

        &__calendar {
            min-width: 250px;
        }

        &__time-periods {
            .list-group-item {
                border: 0;
            }
        }
    }

    // override the primeng month/year default width...
    p-calendar>span {
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .kui-daterange-popup {

        // Dropdown positioning is pretty bad when in mobile, 
        // let's just center the dropdown on the screen and hope it's good enough
        .dropdown-menu {
            padding: 0;
            width: 80%;
            transform: none !important;
            position: absolute;
            left: 0;
            right: 0;
            top: 10%;
            margin-left: auto;
            margin-right: auto;
        }

        .date-selector {
            &__panel {
                display: flex;
                flex-direction: column;

                .confirm {
                    width: 100%;
                }
            }
        }
    }
}

// Override our default primeng calendar styling
.kui-calendar {

    // include default calendar styling
    @include calendar();

    user-select: none;

    // put the timepicker above the calendar, as it feels more natural
    &.p-datepicker-inline:has(.p-timepicker) {
        display: flex;
        flex-direction: column-reverse;
    }

    // reduce some of the padding in the timepicker
    .p-timepicker {
        padding: 0;

        .p-hour-picker>span,
        .p-minute-picker>span,
        .p-second-picker>span {
            padding: 0;
        }
    }

    // add some spacing between the month/year picker and calendar 
    .p-datepicker-group {
        display: flex;
        flex-direction: column;
    }

}