

.content-sidebar {

    background-color: var(--sidebar-background-color);
    // border-left: 1px solid var(--sidebar-border-color);


    font-family: var(--sidebar-font-family);
    font-size: var(--sidebar-font-size);
    color: var(--sidebar-font-color);

}

.content-sidebar * {
    border-color: var(--sidebar-border-color);

}