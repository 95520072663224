@use 'sass:math';

kui-modal-header,
kui-modal-body,
kui-modal-footer {
    display: block;
}

kui-modal-body.modal-body {
    background-color: var(--modal-background-color);
    color: var(--content-font-color);
    padding: 0; // let consumers decide the padding
}

kui-modal {
    .modal-content {
        background-color: var(--modal-background-color);
        color: var(--content-font-color);
        border-color: var(--modal-border-color);
        border-width: var(--modal-border-width);
    }
}

// not adding this above cause ELEMENT.CLASS has too strong specificity to overwrite without !important on things like form borders and buttons
.modal-body * {
    border-color: var(--content-border-color);
    // assume that all <pre> tags require wrapping since modals can't stretch or scroll
    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}

kui-modal-header.modal-header {

    background-color: var(--modal-header-background-color);
    color: var(--modal-header-font-color) !important;
    border-bottom: var(--width-border) solid var(--modal-header-border-color);

    h4 {

        font-family: var(--modal-header-font-family) !important;
        font-size: var(--modal-header-font-size) !important;
        font-weight: var(--modal-header-font-weight) !important;
        text-transform: var(--modal-header-font-transform);

    }

    #close {
        cursor: pointer;
        align-self: center;
    }

    .modal-header-icons {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

kui-modal-footer.modal-footer {

    border-top: var(--width-border) solid var(--modal-footer-border-color);
    background-color: var(--modal-footer-background-color);
    color: var(--modal-footer-font-color) !important;

    font-family: var(--modal-footer-font-family);
    font-size: var(--modal-footer-font-size);

    .btn {
        margin-left: math.div($padding, 2);
        margin-left: calc(var(--padding) / 2);

        @include media-breakpoint-down(sm) {
            margin-left: math.div($padding, 4);
            margin-left: calc(var(--padding) / 4);
        }
    }

}

.modal-backdrop {
    z-index: map-get($zindex, modal) - 1; // 1 less than modal content

    &.show {
        opacity: $opacity-modal-backdrop;
        opacity: var(--opacity-modal-backdrop);
    }
}

.modal-content {
    @include overlay-dropshadow();
}

.modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: map-get($zindex, modal);
    background-color: rgba(255, 255, 255, 0.5);
}

.modal {
    z-index: map-get($zindex, modal);
}