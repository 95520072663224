.tree-select {
    padding: 0 .5rem;
    .tree-children.tree-children-no-padding {
        padding-left: 0
    }
    .tree-children {
        padding-left: 20px;
        overflow: hidden
    }
    .node-drop-slot {
        display: block;
        height: 2px
    }
    .toggle-children-wrapper-expanded .toggle-children {
        @extend .icon;
        @extend .icon-chevron-down;
        transform: none;
        width: 1rem;
        height: 1rem;
    }
    .toggle-children-wrapper-collapsed .toggle-children {
        @extend .icon;
        @extend .icon-chevron-right;
        transform: none;
        width: 1rem;
        height: 1rem;
    }
    .toggle-children-placeholder,
    .toggle-children-wrapper {
        cursor: pointer;
        width: 1rem;
        display: inline-block;
        padding-top: .5rem;
        padding-left: .2rem;
        opacity: .5;
    }
    .toggle-children {
        background: none;
        font-size: .9rem;
    }
    .node-content-wrapper {
        cursor: pointer;
        display: inline-block;
        padding: $padding * .5;
        padding: calc(.5 * var(--padding));
        border: solid $width-border transparent;
        border: solid var(--width-border) transparent;
        transition: background-color .25s;
        flex-grow: 1;
        white-space: nowrap;
    }

    .node-wrapper {
        display: flex;
        align-items: flex-start;
    }
    .node-wrapper-active,
    .node-content-wrapper.node-content-wrapper-active:hover,
    .node-content-wrapper-active.node-content-wrapper-focused {
        color: var(--tree-active-font-color);
        background: var(--tree-active-background-color);
    }
    .node-wrapper-focused,
    .node-wrapper:hover {

        color: var(--tree-active-font-color);
        background: var(--tree-active-background-color);

        box-shadow: none;
    }

    @for $i from 2 through 10 {
        .tree-node-level-#{$i} {
            padding: 0;
            tree-node-expander {
                margin-left: 20px * ($i - 1);
            }
            tree-loading-component{
                margin-left: (20px * ($i - 1)) + 30px;
            }
        }
    }

    .tree-node-leaf {
        padding-left: 10px;
    }

    .tree-children,
    .tree-node-leaf {
        // remove original padding
        padding-left: 0;
    }

    .node-drop-slot {
        // causes lines where not needed
        display: none;
    }

}
