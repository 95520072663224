//
// main app header
//

.app-header {
    height: $height-header;
    padding-top: 0; // NOTE: bootstrap overwrite
    padding-bottom: 0; // NOTE: bootstrap overwrite
    padding-left: 0; // NOTE: bootstrap overwrite
    z-index: map-get($zindex, base);

    background-color: var(--header-background-color);

    .navbar-brand {
        padding-top: 0; // NOTE: bootstrap overwrite
        padding-bottom: 0; // NOTE: bootstrap overwrite
    }

    .mobile-header-hamburger {
        display: inline-block;
        line-height: var(--height-header);
        padding-right: var(--padding);
        padding-left: var(--padding);
        color: var(--header-font-color);
    }

    @extend .fixed-top;

    .brand {
        display: none !important;
    }

    @include media-breakpoint-up(md) {
        .brand {
            display: none !important;
        }

        //@if $menu-position == 'left' {
            left: var(--width-aside);
        //}

        .mobile-header-hamburger {
            display: none;
        }

    }

}

//
// content header
//

.app-content-header {
    background-color: var(--header-background-color);

    &.fixed-top,
    &.absolute-top {
        left: 0;
        right: 0;
        z-index: map-get($zindex, base);
    }

    &.fixed-top {
        position: fixed;

        top: var(--height-header);

        @include media-breakpoint-up(md) {
            //@if $menu-position == 'left' {
                left: var(--width-aside);
            //}

            .sidebar-toggled & {
                //@if $menu-position == 'left' {
                    left: var(--width-aside-collapsed);
                //}
            }
        }
    }

    &.absolute-top {
        position: absolute;
        top: 0;
    }

}

//
// shared header styles
//

.app-header,
.app-content-header {
    @extend %navbar-brightness;
}

    .navbar {
        @include media-breakpoint-down(sm) {
            flex-direction: column;

            &.app-header {
                @include position-inline-navbar;
            }

            .app-content-header & {
                @include position-inline-navbar;
            }

        }
    }

    .content-header-title {
        height: 100%;
        @extend .d-flex;
        @extend .align-items-center;
    }

    kui-header-nav {
        .header-nav-buttons,
        .header-nav-links {
            @extend .d-flex;

            .nav-item:not(:last-child) {
                margin-right: var(--padding);
            }
        }

        .header-nav-buttons {
            @if $header-buttons-small {
                .btn {
                    @extend .btn-sm;
                }
            }
        }

        .header-nav-links {
            @if $header-links-text-hide == 'always' {
                .nav-link-text {
                    display: none;
                }

                @include media-breakpoint-up(md) {
                    @include convert-to-tooptip(nav-link, nav-link-text);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .header-nav-buttons,
            .header-nav-links {
                width: 100%;
            }

            .header-nav-links {
                @if $header-links-text-hide == 'on-mobile' {
                    .nav-link-text {
                        display: none;
                    }
                }
            }

        }


        @include media-breakpoint-up(md) {
            margin-left: auto;

            .header-nav-buttons,
            .header-nav-links {
                margin-left: $padding;
                margin-left: var(--padding);
            }

            .header-nav-links {
                @if $header-links-text-hide == 'on-desktop' {
                    @include convert-to-tooptip(nav-link, nav-link-text);
                }
            }
        }
    }
