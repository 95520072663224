kui-side-panel {
    flex-basis: $width-aside;
    flex-basis: var(--width-aside);
    width: $width-aside;
    width: var(--width-aside);
    min-width: 0px;
    order: 2;
    overflow: hidden;
    display: flex;
    z-index: map-get($zindex, sidenav) + 1;

    &.shadow {
        transition: box-shadow .2s .1s ease;
    }

    &.right {
        right: 0;

        &.shadow.position-absolute {
            box-shadow: -2px 0 10px -3px rgba(0, 0, 0, $drop-shadows-opacity);
            box-shadow: -2px 0 10px -3px rgba-emit(var(--drop-shadows-opacity));
        }
    }

    &.left {
        left: 0;

        &.shadow.position-absolute {
            box-shadow: 2px 0 10px -3px rgba(0, 0, 0, $drop-shadows-opacity);
            box-shadow: 2px 0 10px -3px rgba-emit(var(--drop-shadows-opacity));
        }
    }

    &.position-absolute {
        top: 0;
        bottom: 0;
    }

    > kui-card {
        display: flex;
        flex: 1;
        min-width: 0px;

        .content-card {
            flex: 1;
        }

        .content-card-block {
            flex: 1;
            border-width: 0 $width-border;
            border-width: 0 var(--width-border);
        }

        kui-card-body {
            max-height: 100%;
        }
    }

    kui-side-panel-title {
        display: block;
        padding: $padding * 1.25;
        padding: calc(var(--padding) * 1.25);
        border-bottom-style: solid;
        border-bottom-width: $width-border;
        border-bottom-width: var(--width-border);

        h3 {
            margin: 0;
        }
    }

}