.lg-column {
    position: relative;

    .resizable-container {
        flex: 1 1 auto;
        min-width: 0; // reset min-width to prevent some weird behaviors https://www.w3.org/TR/css-flexbox-1/#min-size-auto

        &__bottom {
            // padding-bottom: $width-border * 4;
            // padding-bottom: calc(var(--width-border) * 4);
            flex-direction: column;
        }

        // &__right {
        //     padding-right: $width-border * 4;
        //     padding-right: calc(var(--width-border) * 4);
        // }
    }

    .non-resizable-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        flex-basis: 100% !important;
    }
}

