:root {
    // drop shadows
    --enable-drop-shadows: #{if($enable-drop-shadows, 1, 0)};
    --drop-shadows-opacity: #{$drop-shadows-opacity};

    // border-radius
    --enable-rounded-corners: #{if($enable-rounded-corners, 1, 0)};
    --rounded-corners-radius: #{$rounded-corners-radius};

    // widths
    --width-aside: #{$width-aside};
    --width-aside-collapsed: #{$width-aside-collapsed};
    --width-border: #{$width-border};

    // heights
    --height-header: #{$height-header};

    // padding
    --padding: #{$padding};

    // opacity
    .modal-backdrop {
        --opacity-modal-backdrop: #{$opacity-modal-backdrop};
    }

    // scrollbars
    --scrollbar-track-color: #{$scrollbar-track-color}    ;
    --scrollbar-handle-color: #{$scrollbar-handle-color};
    --scrollbar-handle-padded: #{if($scrollbar-handle-padded, 1, 0)};

    --color-click-animation: #999999;

    // fonts
    --font-family: #{if($font-sans-serif, map-deep-get($fonts-sans-serif-map, $font-sans-serif, font-family), ''), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif};
    --keep-default-font-size: #{if($keep-default-font-size, 1, 0)};
    --font-size-default: #{$font-size-default};
    --font-size-for-lg: var-if(var(--keep-default-font-size), var(----font-size-default), calc(var(--font-size-default) * 1.25));
    --font-size-for-sm: var-if(var(--keep-default-font-size), var(----font-size-default), calc(var(--font-size-default) * .875));

    --icon-weight: #{map-get($icons-weight-map, $icon-weight)};
   

}
