kui-option-panel {

    //tiered menu - desktop
    p-tieredmenu {

        * {
            outline: 0;
        }

        // this component has an issue that it doesn't open the subitems until one has been clicked,
        // see https://github.com/primefaces/primeng/issues/13861 for more information

        .p-tieredmenu,
        .p-tieredmenusub {
            z-index: map-get($zindex, dropdown) !important;
            position: fixed;
            display: block !important;
            top: 0;
            background-color: var(--header-background-color);

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            .p-tieredmenu-root-list,
            .p-submenu-list {
                padding: 0;
                background-color: var(--header-background-color);
                border: solid var(--input-border-width) var(--input-border-color);
                width: 16.5em;
                box-shadow: 0 3px 10px rgba(0, 0, 0, calc(var(--drop-shadows-opacity) * 1.5));
            }

            .p-submenu-icon {
                position: absolute;
                margin-top: calc(-.5 * var(--padding));
                right: 0;
                top: 50%;
            }

            .p-menuitem-icon {
                @include icon(null);
                margin-right: .25em;
                vertical-align: middle;
            }

            .p-menuitem-link {
                display: grid;
                grid-template-columns: 1fr 0.1fr;
                grid-template-areas: "text icon";
                padding: calc(.75 * var(--padding));
                color: var(--header-font-color);
                border-radius: 0;
                transition: box-shadow 0.2s;
                user-select: none;
                cursor: pointer;
                position: relative;
                text-decoration: none;

                .p-menuitem-text {
                    grid-area: text;
                    color: var(--header-font-color);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    vertical-align: middle;
                }

                .p-menuitem-icon {
                    grid-area: icon;
                }

                &:not(.p-state-disabled):hover {
                    background: var-darken(var(--input-background-color), .1);
                }
            }

            .p-menuitem-active {
                background: var-darken(var(--input-background-color), .1);
            }
        }

    }

    // slide menu - mobile
    p-slidemenu {
        .p-slidemenu {
            z-index: map-get($zindex, dropdown) !important;
            width: 200px;
            padding: 0;
            background-color: var(--header-background-color);
            box-shadow: 0 3px 10px rgba(0, 0, 0, calc(var(--drop-shadows-opacity) * 1.5));
        }

        .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
            background: var-darken(var(--input-background-color), .1);
        }

        .p-slidemenu .p-menuitem-link:not(.p-state-disabled):hover {
            background: var-darken(var(--input-background-color), .1);
        }

        .p-slidemenu .p-menuitem-link {
            padding: .75 * $padding;
            padding: calc(.75 * var(--padding));
            color: var(--header-font-color);
            -moz-border-radius: 0;
            -webkit-border-radius: 0;
            border-radius: 0;
            -moz-transition: box-shadow 0.2s;
            -o-transition: box-shadow 0.2s;
            -webkit-transition: box-shadow 0.2s;
            transition: box-shadow 0.2s;
            user-select: none;
        }

        .p-slidemenu .p-menuitem-link {
            padding: .75 * $padding;
            padding: calc(.75 * var(--padding));
            display: block;
            position: relative;
            text-decoration: none;
            cursor: pointer;
        }

        .p-slidemenu.p-slidemenu-dynamic {
            position: absolute;
        }

        .p-slidemenu .p-menu-separator {
            border-width: 1px 0 0 0;
        }

        .p-slidemenu ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .p-submenu .p-submenu-list {
            background-color: var(--header-background-color);
        }

        .p-slidemenu .p-slidemenu-rootlist {
            position: absolute;
            top: 0;
        }

        .p-slidemenu .p-submenu-list {
            display: none;
            position: absolute;
            top: 0;
            padding: 0;
        }

        .p-slidemenu .p-menuitem-link {
            padding: .75 * $padding;
            padding: calc(.75 * var(--padding));
            display: block;
            position: relative;
            text-decoration: none;
            outline: 0;
        }

        .p-slidemenu .p-menuitem-icon {
            margin-right: .25em;
            vertical-align: middle;
        }

        .p-slidemenu .p-menuitem-text {
            vertical-align: middle;
        }


        .p-slidemenu .p-menuitem {
            position: relative;
            margin: 0;
        }

        .p-slidemenu .p-menuitem-link .p-submenu-icon {
            position: absolute;
            margin-top: calc(-.5 * var(--padding));
            right: 0;
            top: 50%;
        }

        .p-slidemenu .p-slidemenu-wrapper {
            position: relative;
        }

        .p-slidemenu .p-slidemenu-content {
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
        }

        .p-slidemenu-backward {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: .75 * $padding;
            padding: calc(.75 * var(--padding));
            cursor: pointer;
            display: none;
            font-weight: bold;
            border-radius: 0;
            border-top-style: solid;
            border-top-width: $width-border;
            border-top-width: var(--width-border);
        }

        .p-slidemenu-backward {
            kui-icon {
                vertical-align: middle;
                margin-left: 0.25em;
                margin-right: 0.5em;
            }
        }

        .p-slidemenu .p-menuitem-active {
            position: static;
        }

        .p-slidemenu .p-menuitem-active > .p-submenu > .p-submenu-list {
            display: block;
        }

        .p-slidemenu ul:not(.p-active-submenu) > .p-menuitem:not(.p-menuitem-active),
        .p-slidemenu .p-active-submenu > .p-menuitem-active > .p-submenu > .p-submenu-list {
            display: none;
        }

        .p-slidemenu .p-active-submenu > .p-menuitem-active ~ .p-menuitem {
            display: block;
        }
    }

    .menu-collapsed {
        kp-option-panel p-tieredmenu .p-tieredmenu {
            transform: translate(-22%, 0) !important;
        }
    }

}