.feed-title {
    border-bottom-style: solid;
    margin-bottom: 0;
    border-width: var(--width-border);
    padding: var(--padding);
    background-color: var(--feed-header-background-color);
    color: var(--feed-header-font-color);
    border-color: var(--feed-header-border-color);
}

.feed-entry {
    display: flex;
    flex-direction: row;

    padding: var(--padding);

    border-bottom-style: solid;
    border-bottom-width: var(--width-border);

    background-color: var(--feed-background-color);
    color: var(--feed-font-color);
    border-color: var(--feed-border-color);

    + .feed-title {
        padding-top: $padding;
        padding-top: var(--padding);
    }

    .small {
        font-size: .85rem;
        display: block;
        white-space: nowrap;
    }

    &:hover {
        color: var(--feed-active-font-color);
        background: var(--feed-active-background-color);

        &.hover,
        .hover {
            opacity: 1;
        }
    }

    &.selected,
    .selected & {
        color: var(--feed-active-font-color);
        background: var(--feed-active-background-color);
    }

    .feed-entry-info {
        border-color: var(--feed-border-color);
        border-right-style: solid;
        border-right-width: $width-border;
        border-right-width: var(--width-border);

        margin-right: $padding;
        margin-right: var(--padding);

        padding-right: $padding;
        padding-right: var(--padding);

        .info-header {
            display: flex;
            align-items: center;

            
            svg,
            i {
                margin-left: 5px;
            }

            svg {
                width: 1.05rem;
                height: 1.05rem;
                fill: var(--color-warning-color);

                &.text-danger {
                    fill: var(--color-danger-color);
                }

                &.text-success {
                    fill: var(--color-success-color);
                }
            }


        }

        .info {

            .feed-icons {
                @extend .d-flex;
                @extend .align-items-center;
                @extend .ml-auto;
                z-index: map-get($zindex, one);
                margin-top: 5px;

                .feed-icon {
                    position: relative;

                    &.feed-icon-rating-active,
                    &.feed-icon-rating-mixed {
                        position: absolute;
                        right: 0;
                        top: 2rem;
                    }

                }
            }

            kui-icon {
                line-height: 1;

                i {
                    font-size: 110% !important;
                }
            }
        }

        .info-container {
            position: relative;
            white-space: nowrap;

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .info {
                border-radius: inherit;
                padding: 0;
            }
        }

        [class*="alert-"] {
            border-right: 0;
        }

        .no-alert {
            background-color: rgba(0, 0, 0, .05);
        }
    }

    .feed-entry-actions {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        border-left-style: solid;
        border-left-width: $width-border;
        border-left-width: var(--width-border);

        margin-left: $padding;
        margin-left: var(--padding);

        kui-icon {
            display: block;

            padding-bottom: $padding;
            padding-bottom: var(--padding);

            padding-left: $padding;
            padding-left: var(--padding);
        }
    }

    .feed-entry-details {
        flex: 1;

        .accent {
            font-weight: 500;
        }

        .additional i {
            font-size: 100% !important;
            font-weight: bold;
        }

        #entry-title,
        .entry-title {
            margin-bottom: 0.5rem;
        }

        .icon {
            color: var(--list-icon-color);
        }

    }

    .feed-entry-rating {
        width: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding: 0 $padding;
        padding: 0 var(--padding);

        svg {
            width: 1.5rem;
            height: 1.5rem;

            fill: var(--color-warning-color);

            &.text-danger {
                fill: var(--color-danger-color);
            }

            &.text-success {
                fill: var(--color-success-color);
            }
        }
    }
}

.feed-footer {
    display: block;

    padding: $padding 0;
    padding: var(--padding) 0;
}

.feed-item-trip {
    .info {
        min-width: 75px;
    }
}

.media-thumbnail {
    img {
        border-style: solid;
        border-width: var(--width-border);
        padding: 4px;
        width: 150px;
        min-height: 96px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
}