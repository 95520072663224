.form-control,
.custom-file-control {
    border: solid var(--input-border-width) var(--input-border-color);

    &:focus {
        color: var(--input-font-color);
        background-color: var(--input-background-color);
        border-color: var(--color-primary-color);

    }
}

.input-group-addon,
.input-group-btn > .btn {
    border: solid var(--input-border-width) var(--input-border-color);

    @extend .btn-secondary;
    box-shadow: none !important;

}

input,
textarea,
select,
.form-control,
.color-picker-label {
    line-height: inherit !important;

    font-size: #{$font-size-default}px !important;
    font-size: calc(var(--font-size-default) * 1px) !important;
}

input,
textarea,
select,
.select-dropdown .dropdown-toggle,
.form-control,
.custom-file-control {
    color: var(--input-font-color);
    background-color: var(--input-background-color);

    &:disabled {
        opacity: 0.5 !important;
    }

    &::-webkit-input-placeholder {
        -webkit-text-fill-color: var(--input-font-color);
        opacity: .8;
    }

    &::-moz-placeholder {
        color: var(--input-font-color) !important;
        opacity: .8;
    }

    &:-ms-input-placeholder {
        color: var(--input-font-color) !important;
        opacity: .8;
    }

    &:-moz-placeholder {
        color: var(--input-font-color) !important;
        opacity: .8;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--input-font-color) !important;
        box-shadow: 0 0 0px 1000px var(--input-background-color) inset !important;
    }
}

.color-picker-label {
    display: inline-block;
    width: auto;
    cursor: pointer;

    &.small {
        padding: rem-calc($font-size-default * 0.5);
        padding: var-rem-calc(calc(var(--font-size-default) * 0.5));
    }

    &.large {
        padding: rem-calc($font-size-default);
        padding: var-rem-calc(var(--font-size-default));
    }

    &.transparent {
        position: relative;
        overflow: hidden;

        $color-picker-transparent: rgba(0, 0, 0, .2);

        &:before {
            content: "";
            position: absolute;
            width: 2 * 100%;
            height: 2 * 100%;
            top: -50%;
            left: -50%;
            background-color: var(--main-background-color);
            background-size: 25% 25%;
            background-image: linear-gradient(45deg, $color-picker-transparent 25%, transparent 25%, transparent),
                linear-gradient(-45deg, $color-picker-transparent 25%, transparent 25%, transparent),
                linear-gradient(45deg, transparent 75%, $color-picker-transparent 75%),
                linear-gradient(-45deg, transparent 75%, $color-picker-transparent 75%);
            transform: scale(1) rotate(45deg);
        }
    }
}

button {
    cursor: pointer;

    &.btn-light {
        @extend .border;
    }
}

kui-select {
    .btn-light {
        background: #ffffff;
    }
}

kui-input-group {
    kui-select {
        flex: auto;
    }
}

//
// layout label and fields inline
//
.form-group.inline {
    display: flex;

    .form-label {
        width: 33%;
    }

    .form-field {
        flex: 1;
        min-width: 30%;
        max-width: 64%;
        margin-left: auto;

        /* IE11 hack to remove the `margin-left: auto` as this causes issues in the report builder */
        @media all and (-ms-high-contrast:none) {
            margin-left: 0 !important;
        }

    }
}

//
// This is supposed to work out of the box for bootstrap, but doesn't
//
.custom-file-control {

    &:before,
    &:after {
        bottom: 0;
        display: flex;
        align-items: center;
    }

    &:before {
        content: 'Browse';
        height: unset;
        border-left: solid var(--input-border-width) var(--input-border-color);
    }

    &:after {
        content: 'Choose file...';
        position: absolute;
        top: 0;
    }
}

.form-label {
    padding-top: $input-btn-padding-y;
}


.form-check-input {
    position: relative;
    vertical-align: middle;
    bottom: .2em;
    margin-right: .2em;
    margin-left: 0;
}

.form-control {
    height: unset;
}