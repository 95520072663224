// shared styles for list sections. Some lists might have their own additional styles in the directory, but these babies are shared.
.list-view {


    kui-side-panel {
        top: calc(var(--height-header) - var(--width-border));
        right: 0;

        @include media-breakpoint-up(md) {
            right: calc(var(--width-border) * 4);
        }
    }

    &__option-item {
        * {
            cursor: pointer;
        }

        > div {
            @extend .table-hover-state;
            @extend .p-1;
        }
    }
}

.listing {
    overflow: hidden;

    background-color: var(--list-background-color);
    border-color: var(--list-border-color);

    &--hover {
        &:hover:not(.listing--active) {
            opacity: 1;
            color: var(--list-active-font-color);
            background-color: var(--list-active-background-color);
        }
    }

    &--active {
        color: var(--list-active-font-color);
        background-color: var(--list-active-background-color);

        .listing__icon {
            color: var(--list-active-icon-color);
        }

    }

    &__stat {
        white-space: nowrap;
        text-align: right;
        align-self: flex-start;
    }

    &__icon {
        margin: 3px;
        color: var(--list-icon-color);
    }

}

