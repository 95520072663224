
:root {
    @include media-breakpoint-down(sm) {
        // increase the base rem size on mobile devices
        font-size: 14px;
    }
}

//
// custom scrollbar for webkit, Firefox & IE
//
.scrollbars-visible ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-button {
    display: none;
}

// track
::-webkit-scrollbar-corner,
::-webkit-resizer,
::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

// handle
::-webkit-scrollbar-thumb {
    // no need for fallbacks here
    background: var(--scrollbar-handle-color);
    border-style: solid;
    border-color: transparent;
    border-width: var-if(var(--scrollbar-handle-padded), 3px, 0px);
    border-radius: var-if(var(--enable-rounded-corners), 20px, 0px);
    background-clip: padding-box;
}

* {
    // IE
    // NOTE: removing ie11 custom scrollbars for now because it does not work with rgba colors
    // TODO: We can revisit this later
    // scrollbar-highlight-color: var(--scrollbar-track-color);
    // scrollbar-track-color: var(--scrollbar-track-color);
    // scrollbar-base-color: var(--scrollbar-handle-color);
    // scrollbar-face-color: var(--scrollbar-handle-color);
    // scrollbar-3dlight-color: var(--scrollbar-handle-color);
    // scrollbar-arrow-color: var(--scrollbar-handle-color);
    // scrollbar-shadow-color: var(--scrollbar-handle-color);

    // Firefox
    scrollbar-color: var(--scrollbar-handle-color) var(--scrollbar-track-color);
    scrollbar-width: thin;
}

html,
body {
    width: 100%;
    height: 100%;
}

html {
    font-size: rem-calc($font-size-default, 16);
}

body {
    font-family: var(--main-font-family);
    font-size: var(--main-font-size);
    color: var(--main-font-color);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

hr {
    border-width: $width-border;
    border-width: var(--width-border);
}

.disable-user-select {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
