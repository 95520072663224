// shared styles for detail sections. Some details might have their own additional styles in the directory, but these babies are shared.

.details-overview  {
  display: block;
  padding: var-if(var(--enable-rounded-corners), (var(--padding) / 2), 0rem);


    &__summary p:last-child {
      margin-bottom: 0;
    }

    &__icon-container {
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }

  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
  }
}

.details-container {
    .sidebar {
        background-color: var(--content-background-color);
        border-left: var(--content-border-color) solid var(--width-border);
    }
}
