@use 'sass:math';

.menu-mobile-toggled {
    overflow: hidden;
}

.main-nav {
    background: var(--navigation-background-color);

    .main-nav-link,
    .main-nav-link:not([href]):not([tabindex]) {
        color: var(--navigation-font-color);
        font-size: var(--navigation-font-size);
        font-weight: var(--navigation-font-weight);
        text-transform: var(--navigation-font-transform);
    }

    kui-icon i {
        margin-right: .35rem;
        vertical-align: middle;
    }
}

.nav-section {
    @if $navigation-active-style=='background' {
        @if $enable-rounded-corners {
            padding-left: math.div($padding, 2);
        }

        padding-left: var-if(var(--enable-rounded-corners), calc(var(--padding) / 2), 0px);
    }

    .nav-link-text {
        margin-left: var(--padding);
        flex: 1;
        text-align: left;
    }

}

.nav-title {
    text-transform: uppercase;
    padding: $padding $padding 0;
    padding: var(--padding) var(--padding) 0;
    display: block;
}

.main-nav-link {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;

    .nav-link-dropdown-arrow {
        i {
            margin-right: 0px;
            transition: transform .3s;
            transform: rotate(90deg);
        }
    }

    ~ .sub-nav {
        display: none;
    }

    .nav-section.open &,
    &.open {
        .nav-link-dropdown-arrow i {
            transform: rotate(0deg);
        }

        ~ .sub-nav {
            display: block;
        }
    }

}

.nav-section .sub-nav {
    padding-top: calc(var(--padding) / 2);
    padding-bottom: calc(var(--padding) / 2);

    .main-nav-link,
    .main-nav-link:not([href]):not([tabindex]) {
        color: var(--navigation-subnav-font-color);
    }

    .main-nav-link {
        white-space: nowrap;
    }

    .nav-link-text {
        margin-left: unset;
    }

    &__items {
        padding-top: calc(var(--padding) / 2);
        padding-bottom: calc(var(--padding) / 2);
    }
}

@include media-breakpoint-up(md) {
    body:not(.menu-collapsed) {
        @include navbar-expanded();
    }

    body.menu-collapsed {
        @include navbar-collapsed();
    }
}