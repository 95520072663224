//
// css varaible replacements
//

//
// text
//

.hover {
    &:hover {
        opacity: 0.8;
    }
}

kui-icon.hover {
    &:hover  {
        opacity: 1 !important;
        color: var(--color-primary-color);
    }
}

@each $color, $value in $theme-colors {
    .text-#{$color} {
        color: var(--color-#{$color}-color) !important;
    }

    a.text-#{$color} {
        &:hover, &:focus {
            @include var-darken-text(var(--color-#{$color}-color), .25);

            * {
                @include var-darken-text(var(--color-#{$color}-color), .25);
            }
        }
    }
}

// //
// // extra colors
// //
// $extra-colors: (
//     bg-card-header: $color-bg-card-header,
//     bg-card-block: $color-bg-card-block,
//     bg-card-footer: $color-bg-card-footer,
//     text-default: $color-text-default,
//     bg-main: $color-bg-main,
//     border: $color-border,
// );

// @each $color, $value in $extra-colors {
//     .text-#{$color} {
//         color: var(--color-#{$color}-color) !important;
//     }
// }

//
// background
//
@each $color, $value in $theme-colors {
    .bg-#{$color} {
        color: var(--color-#{$color}-contrast) !important;
        background: var(--color-#{$color}-color) !important;
    }

    a.bg-#{$color} {
        &:hover,
        &:focus {
            background: var-darken(var(--color-#{$color}-color)) !important;
        }
    }
}


// $extra-bg-colors: (
//     card-header: $color-bg-card-header,
//     card-block: $color-bg-card-block,
//     card-footer: $color-bg-card-footer,
//     main: $color-bg-main,
// );

// @each $color, $value in $extra-bg-colors {
//     .bg-#{$color} {
//         color: color-contrast($value) !important;
//         color: var(--color-bg-#{$color}-contrast) !important;
//         background: $value !important;
//         background: var(--color-bg-#{$color}) !important;
//     }

//     a.bg-#{$color} {
//         &:hover,
//         &:focus {
//             background: darken($value, 5%) !important;
//             background: var-darken(var(--color-bg-#{$color})) !important;
//         }
//     }
// }

.bg-main {
    color: var(--main-font-color) !important;
    background: var(--main-background-color) !important;
}

.bg-content {
    color: var(--content-font-color) !important;
    background: var(--content-background-color) !important;
}

.bg-card-header {
    color: var(--header-font-color) !important;
    background: var(--header-background-color) !important;
}

.bg-card-block {
    color: var(--content-font-color) !important;
    background: var(--content-background-color) !important;
}

.bg-card-footer {
    color: var(--footer-font-color) !important;
    background: var(--footer-background-color) !important;
}

