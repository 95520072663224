//
// collapsed state of the user profile on tablet and desktop
//
@mixin user-collapsed-sidebar() {
    padding: calc(var(--padding) / 2);
    margin-bottom: var(--padding);
    height: var(--height-header);

    .user-profile-button {
        text-align: center;
    }

    .profile-image {
        width: 100%;
    }
}

//
// navigation-active-style
//
@mixin nav-active-border-indicator($border-side: 'left') {
    content: '';
    #{$border-side}: 0;
    top: 0;
    bottom: 0;
    width: calc(var(--width-border) * 4);
    background: var(--color-primary-color);
    border-radius: var-if(var(--enable-rounded-corners), calc(var(--rounded-corners-radius) * 4), 0px);
}

@mixin nav-active-border($border-side: 'left') {
    border-radius: 0 !important;
    color: var(--color-primary-color) !important;

    &:before,
    + .sub-nav a.active:before {
        @include nav-active-border-indicator($border-side);
    }

    &:not(:only-child) {
        &:before {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        + .sub-nav a.active:before {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    & .hover:hover,
    &.hover:hover {
        &:before {
            background: var-darken(var(--color-primary-color), .25);
        }

        .nav-link-text,
        i {
            @include var-darken-text(var(--color-primary-color), .25);
        }
    }
}

//
// collapsed state of the navbar on tablet and desktop
//
@mixin navbar-collapsed() {

    .nav-title {
        border-top-style: solid;
        border-top-width: var(--width-border);
        margin-top: var(--padding);
    }

    .main-nav {
        text-align: center;

        kui-icon i {
            margin-right: 0 !important;
        }

        .main-nav-link:after,
        .nav-link-text,
        .sub-nav,
        .nav-link-dropdown-arrow {
            display: none !important;
        }

        .nav-section {
            position: relative;

            &__item {
                position: relative;

                &:hover {
                    .sub-nav {
                        display: block !important;

                        .nav-link-text {
                            display: block !important;
                        }
                    }
                }
            }
        }

        .main-nav-link {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .sub-nav {
            min-width: 200px;
            position: absolute;
            margin-top: 0;
            padding-top: 0;
            filter: drop-shadow(2px 1px 5px rgba-emit(calc(var(--drop-shadows-opacity))));
            z-index: map-get($zindex, one);

            .main-nav-link {
                position: relative;
                justify-content: flex-start;
                overflow: visible;
            }

            &__title {
                display: flex;
                text-align: left;
                white-space: nowrap;
                padding-left: 0 !important;

                &:hover {
                    opacity: 1;
                }

                kui-icon {
                    width: var(--width-aside-collapsed);
                    text-align: center;
                }
            }

            &__items {
                margin-left: var(--width-aside-collapsed);
                background: var(--navigation-subnav-background-color);

                // deeply nested .sub-nav__items
                .main-nav-link:not(:hover) .sub-nav__items {
                    display: none;
                }

                .main-nav-link .sub-nav__items {
                    position: absolute;
                    left: 100%;
                    margin-left: 0;
                }
            }
        }

    }
}

@mixin navbar-expanded() {
    kui-nav {
        overflow: auto;
    }

    .main-nav {
        kui-icon i {
            margin-right: 0;
        }

        .app-menu-content-inner {
            overflow: auto;
        }
    }

    .sub-nav {
        &__title {
            display: none;
        }
    }
}