.content-header * {
    border-color: var(--header-border-color);
}

kui-content-header {
    position: relative;
    z-index: map-get($zindex, overbase);

    .content-header {
        display: flex;
        width: 100%;
        align-items: center;

        height: $height-header;
        height: var(--height-header);

        background-color: var(--header-background-color);
        border-color: var(--header-border-color);

        border-top-style: solid;
        border-top-width: var(--header-border-width);

        border-bottom-style: solid;
        border-bottom-width: var(--header-border-width);

        color: var(--header-font-color);

        &__sub {
            color: var(--subheader-font-color);
            background-color: var(--subheader-background-color);
            border-color: var(--subheader-border-color);
            font-size: var(--subheader-font-size);
            font-family: var(--subheader-font-family);
            font-weight: var(--subheader-font-weight);
            text-transform: var(--subheader-font-transform);
            .btn {
                color: var(--subheader-font-color);
                font-size: var(--subheader-font-size);
                font-family: var(--subheader-font-family);
                font-weight: var(--subheader-font-weight);
                text-transform: var(--subheader-font-transform);
            }
        }

        .mobile-nav {
            i.icon {
                cursor: pointer;
                margin-left: calc(var(--padding) / 2);
                padding: calc(var(--padding) / 2);
            }

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .title {

            font-family: var(--header-font-family);
            font-weight: var(--header-font-weight);
            font-size: var(--header-font-size);
            color: var(--header-font-color);

            @include media-breakpoint-up(md) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: var(--height-header);
                padding: 0 var(--padding);
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
                flex: 1 1 100%;
                display: unset;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .action-mount,
        .icon-mount,
        .tab-mount {
            z-index: map-get($zindex, one);
            white-space: nowrap;

            color: var(--header-font-color);

            kui-button-toolbar,
            kui-content-header-actions,
            kui-tab-bar {
                display: inline-block;

                &:not(kui-tab-bar) {
                    padding: calc(var(--padding) / 2);
                }
            }
        }

        .action-mount {
            flex-grow: 2;

            kui-button-group {
                margin-right: calc(var(--padding) / 2);
            }
        }

        .action-text {
            align-self: center !important;
        }

        .icon-mount {
            flex-grow: 1;
            text-align: right;
            padding-top: 0;
            padding-bottom: 0;


            i.icon {
                cursor: pointer;
                font-size: calc(var(--height-header) * .30) !important;
                padding: 0.3rem;
                min-width: 1.5rem;
                text-align: center;
            }
        }

        .tab-mount,
        kui-tab-bar,
        .tab-bar {
            @include media-breakpoint-up(md) {
                display: flex;
                flex: 0 1 auto;
                min-width: 0;
            }
        }

        .tab-mount {
            align-self: stretch;
            padding: 0;
            text-align: right;

            kui-tab-bar {
                margin: 0;
                height: 100%;
                width: auto;
                position: relative;
                display: inline-block;
                z-index: map-get($zindex, one);

                .tab-bar {
                    height: 100%;
                }

                ol {
                    height: 100%;
                    margin: 0;
                    border: 0;

                    li {
                        border-style: none;
                        border-left-style: solid;
                        border-left-width: var(--width-border);
                    }

                    li:first-child {
                        border: none;
                    }
                }
            }

            &:empty {
                display: none
            }
        }
    }

}