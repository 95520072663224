.app-login {
    display: flex;
    min-height: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;

    .card {
        border: none;
    }

    .app-login-content {
      padding: calc(var(--padding) * 2.5);
    }


    @include media-breakpoint-up(sm) {
        max-width: 340px;

        .app-login-content {
          padding: calc(var(--padding) * 2.5) calc(var(--padding) * 4);
        }

    }
}


.app-login-logo {
    align-self: center;
    text-align: center;

    padding: calc(var(--padding) * 2);

    background-color: var(--navigation-logo-background-color);
    border-bottom: solid var(--navigation-logo-border-width) var(--navigation-logo-border-color);

    min-height: 50px;

    img {
        max-width: 180px;
    }

    @include media-breakpoint-up(sm) {

        img {
            max-width: 200px;
        }
    }
}

.form-control-feedback {
    color: var(--color-danger-color);
}

