@use 'sass:math';

kui-tag-input {
    width: 100%;
    min-width: 0;

    .kui-tag-input {
        + kui-dropdown {
            float: right;
            margin-top: -1 * $input-btn-padding-x * 2;
        }

        &.border-danger .p-autocomplete {
            @extend .border-danger;
        }

        // Container element
        .p-autocomplete {
            display: block;
            border: solid var(--input-border-width) var(--input-border-color);
            border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);

            font-size: $font-size-default;
            font-size: calc(var(--font-size-default) * 1px);

            @if $enable-rounded-corners {
                border-radius: $rounded-corners-radius;
            }

            background-color: var(--input-background-color);

            padding: (rem2px($input-btn-padding-y) - ($width-border * 2)) 0 !important;

            position: relative;

            // Padding is not added when in single select mode (due to token layout)
            > input {
                padding-left: $input-btn-padding-x;
            }

            // Overlay panel of suggestions
            &-panel {
                background-color: var(--input-background-color);
                border: var(--width-border) solid var(input-border-color);
                border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
                color: var(--input-font-color);

                width: 100%;
                position: absolute;
                z-index: $zindex-dropdown;
                overflow: auto;

                @if $enable-rounded-corners {
                    border-radius: $rounded-corners-radius;
                }

                @include overlay-dropshadow();

                // List container of suggestions
                .p-autocomplete-items {
                    padding: 0;
                    list-style: none;
                    margin-bottom: 0;

                    // List item of suggestions
                    .p-autocomplete-item {
                        padding: $padding * .25;
                        padding-left: $input-btn-padding-x;
                        cursor: pointer;

                        &:hover {
                            background: var-darken(var(--input-background-color), .1);
                        }
                    }
                }
            }

            // Container for multiple items
            &-multiple-container { // ul
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                margin-bottom: 0;
                padding-left: 0;
                padding: 0 $input-btn-padding-y;

                // Single item
                .p-autocomplete-token { // li
                    display: block;
                    max-width: 100%;

                    padding-right: math.div($padding, 2);
                    padding-right: calc(var(--padding) / 2);

                    // overwrite more specific classes
                    // background: none !important;
                    background: var(--input-background-color) !important;

                    // badge
                    kui-badge {
                        .badge {
                            display: flex;
                            padding: $badge-padding-y $badge-padding-x;
                            margin-top: 1px;
                            margin-bottom: 1px;
                        }
                    }

                    // Close icon
                    kui-icon i {
                        font-size: inherit !important;
                        padding-left: math.div($padding, 4);
                        padding-left: calc(var(--padding) / 4);
                        cursor: pointer;
                    }
                }

                .p-autocomplete-token-icon {
                    display: none;
                }
                // Text box container
                .p-autocomplete-input-token { // li
                    flex: 1;
                    padding-left: $input-btn-padding-y;
                }
            }

            // outside scope as it is used without the multiple container in single mode
            .kui-tag-input__textbox {
                width: 100%;
                background-color: var(--input-background-color);
                border: none;
                outline: none;
                box-shadow: none;

                font-size: calc(var(--font-size-default) * 1px);
            }

            .p-focus,
            .p-focus .text-primary {
                color: var(--color-primary-contrast) !important;
                background-color: var(--color-primary-color);
            }
        }

        kui-loader {
            position: absolute;
            top: 50%;
            right: 8px;
            margin-top: -8px;
        }
    }
}
