@mixin button($color) {
    color: var(--color-#{$color}-contrast);
    background-color: var(--color-#{$color}-color);
    border-color: var(--color-#{$color}-color);
    transition: opacity 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: var(--button-border-shadow);
    padding: 0.375rem 1rem;

    .action__pulse {
        background-color: var(--color-#{$color}-contrast);
    }

    &:hover,
    &:hover {
        color: var(--color-#{$color}-contrast);
        background-color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);
        opacity: .9;
        box-shadow: var(--button-hover-border-shadow);
    }

    &:focus,
    &.focus {
        box-shadow: var(--button-hover-border-shadow);
        color: var(--color-#{$color}-contrast);
        background-color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);
    }

    &.disabled,
    &:disabled {
        background: var-lighten(var(--color-#{$color}-color));
        border-color: light-overlay();
        opacity: 1 !important;
        box-shadow: none !important;
        cursor: default;
    }

    &:active,
    &.active,
    .show > &.dropdown-toggle {
        color: var(--color-#{$color}-contrast) !important;
        background-color: var(--color-#{$color}-color) !important;
        border-color: var(--color-#{$color}-color) !important;
        box-shadow: var(--button-active-border-shadow);
        opacity: .9;
    }
}

@mixin button-outline($color) {
    color: var(--color-#{$color}-color);
    border-color: var(--color-#{$color}-color);

    .action__pulse {
        background-color: var(--color-#{$color}-contrast);
    }

    &:hover,
    &:hover {
        color: var(--color-#{$color}-contrast);
        background-color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);
    }

    &:focus,
    &.focus {
        box-shadow: var(--button-box-shadow), inset 0 0 0 2px dark-overlay();
        color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);
    }

    &.disabled,
    &:disabled {
        color: var(--color-#{$color}-color) !important;
        background-color: transparent !important;
        cursor: default;
    }

    &:active,
    &.active,
    .show > &.dropdown-toggle {
        color: var(--color-#{$color}-contrast);;
        background-color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);
    }
}