kui-tabs {
    display: block;
    padding-bottom: 1rem;
    position: relative;

    .tab {
        display: none;

        &.active {
            display: block;
        }
    }

    .tab-container {
        @if $enable-rounded-corners {
            border-radius: $rounded-corners-radius;
        }

        border-radius: 0 0
            var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px)
            var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);

        border: var(--width-border) solid var(--main-border-color);

        .tab {
            margin: 0.5em 1em;
            max-height: 20rem;
            max-width: 100%;
            @extend %mobile-scroll;
        }
    }

    .nav {
        margin-bottom: -1px;
    }
}
