.tab-mount {
    @include media-breakpoint-down(sm) {
        display: flex;
        overflow-x: auto;
        position: relative;

        kui-tab-bar {
            overflow-x: auto;
        }

        // :after adds a fadeout at the end of overflowing tabs
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: map-get($zindex, one);
            box-shadow: 0 0 20px 15px var(--tab-background-color);
        }
    }
}

kui-tab-bar {
    display: block;
    flex: 1; // takes effect on mobile
    z-index: map-get($zindex, base);
    width: 100%;

    background-color: var(--tab-background-color);

    .tab-bar {

        text-transform: var(--tab-font-transform);
        font-family: var(--tab-font-family);
        font-size: var(--tab-font-size);
        font-weight: var(--tab-font-weight);

        color: var(--tab-font-color);

        border-color: var(--header-border-color);

        * {
            border-color: var(--header-border-color);
        }

        ol {
            display: flex;
            flex-wrap: no-wrap;
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            overflow: auto;
            box-sizing: content-box;

            height: calc(var(--height-header) - (var(--width-border) * 3));

            border-style: solid;
            border-width: var(--width-border) 0;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 $padding * 1.5;
                transition: .15s all ease-out;
                cursor: pointer;

                font-size: calc(var(--font-size-default) + 1px);

                border-right-style: solid;
                border-right-width: $width-border;
                border-right-width: var(--width-border);
                background-color: var(--tab-background-color);
                color: var(--tab-font-color);

                &.hover:hover:not(.disabled) {
                    background: var-darken(var(--tab-background-color), .1);
                }

                &.active {
                    background: var(--tab-active-background-color);
                    color: var(--tab-active-font-color);

                    &:hover {
                        background: var(--tab-active-background-color);
                        color: var(--tab-active-font-color);
                    }
                }

                kui-icon { 

                    &.tab-icon {
                        // we do not display icons on tabs by default, but this can be 
                        // overwritten in CSS if a theme requires it
                        display: none !important;
                    }

                    i {
                        font-size: calc(var(--height-header) * .30) !important;
                        padding: 0.3rem;
                        min-width: 1.5rem;
                        text-align: center;
                    }

                }

            }

            &.stretch li {
                flex-basis: 100%;
                min-width: 0;

                span {
                    @extend %ellipsis;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            overflow-x: auto;
        }
    }

    @include media-breakpoint-down(sm) {
        kui-menu ~ & {
            display: none;
        }
    }
}


kui-menu ~ kui-tab-bar {
    display: none;
}


kui-content-header .content-header .tab-mount .tab-bar.indicator-bottom,
.tab-bar.indicator-bottom {
    ol {
        border-style: none;

        li {
            border-right-style: none;
            border-left-style: none;

            &.active {
                border-bottom: solid 3px var(--tab-active-indicator-color);
            }
        }
    }
}