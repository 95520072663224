.trip-map-playback {
    .playback-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        width: 100%;

        padding: var(--padding);
        height: var(--height-header);
        background-color: var(--content-background-color);
        color: var(--content-font-color);
        border: solid var(--width-border) var(--content-border-color);

        @if $enable-rounded-corners {
            border-radius: $rounded-corners-radius;
        }

        border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
    }

    &.no-top-radius .playback-bar {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.map-info-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.trip-map-info {
    z-index: 10000;
    position: absolute;

    left: 1rem;
    width: calc(100% - 2rem);
    bottom: calc(1rem + var(--height-header));

    .info-dialog {
        .card, hr {
            border-color: var(--content-border-color);
        }

        .card-block {
            background-color: var(--content-background-color);
            color: var(--content-font-color);

            > div:not(:first-child) {
                padding-left: $padding;
                padding-left: var(--padding);
            }
        }

        &__content {
            flex-grow: 1;
        }

        &__speed-limit {
            padding: 0 $padding;
            padding: 0 var(--padding);
        }
    }
}

@include media-breakpoint-up(md) {
    .map-info-container {
        display: flex;
        flex-direction: column;
        min-width: 20rem + $padding;
        min-width: calc(20rem + var(--padding));
        position: absolute;
        z-index: 10000;
        pointer-events: none;

        @if $enable-drop-shadows {
            filter: drop-shadow(0 2px 5px rgba(0, 0, 0, var(--drop-shadows-opacity)));
        }
        @if $enable-rounded-corners {
            border-radius: $rounded-corners-radius;
        }
        border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);

        .trip-map-playback {
            height: $height-header;
            height: var(--height-header);
            width: calc(20rem + var(--padding));
            pointer-events: auto;
        }

        .trip-map-info {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            pointer-events: auto;

            .info-dialog .card,
            .info-dialog .card-block {
                border-bottom: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
}

.trip-map-info-button {
    .info-button {
        &__nav {
            border-top: solid var(--width-border) var(--main-border-color);
            border-bottom: solid var(--width-border) var(--main-border-color);

            height: $height-header;
            height: var(--height-header);

            background-color: var(--footer-background-color);
            color: var(--footer-font-color);
            border-color: var(--footer-border-color);
            .info-button__nav {
                border-color: var(--footer-border-color);
            }

            display: flex;
            flex-direction: row;
            align-items: center;

            &__prev, &__next {
                width: 25%;

                .v-none {
                    visibility: hidden;
                }
            }

            &__prev {
                text-align: left;
            }

            &__next {
                text-align: right;
            }

            &__text {
                width: 50%;
            }
        }

        &__container {
            display: flex;
            flex-direction: row;

            &__description {
                flex-grow: 1;
            }

            &__button {
                display: flex;
                align-items: center;

                kui-icon {
                    flex-grow: 1;
                }
            }
        }
    }
}

    // this should not be affected by theming
    .road-sign-prohibitory {
        display: inline-block;
        border: solid 4px #cb3130;
        color: #000;
        background: rgba(255, 255, 255, .9);
        font-weight: 600;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 28px;
        font-size: 12px;
    }