@mixin calendar {
    .p-hidden-accessible {
        display: none;
    }

    .p-link {
        background: none;
        border-width: 0px;
    }

    .p-datepicker-header {
        cursor: default;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        padding: calc(var(--padding) * .75);

        button.p-element {
            display: block;
            @extend .icon;
            padding: 3px;

            color: var(--input-font-color);

            &:hover {
                text-decoration: none;
            }

            &.p-datepicker-prev,
            &.p-datepicker-next {
                font-size: 1rem;

                &:hover {
                    opacity: .8;
                }
            }

            &.p-datepicker-prev {
                left: 0;
            }

            &.p-datepicker-next {
                order: 2;
                right: 0;
            }
        }

        .p-datepicker-title {
            display: flex;
            flex: 1 1 auto;
            justify-content: center;

            button,span {
                @extend .form-control;
                display: flex;
                width: auto !important;
                flex-direction: row !important;
                margin: 0 2px;
                box-sizing: content-box;
                align-items: center;
            }
        }
    }

    .p-disabled,
    .p-component:disabled {
        opacity: .3;
    }

    .p-datepicker-calendar {
        width: 100%;
        margin: 0;
        background-color: transparent !important;

        color: var(--input-font-color);


        thead th {
            cursor: default;
            text-align: center;
            padding: calc(var(--padding) * .85) 0;
            background: var(--color-secondary-color);
            color: var(--color-secondary-contrast);
            border-bottom: var(--width-border) solid (--input-border-color);
        }

        td {
            padding: 0;

            border-bottom: var(--width-border) solid (--input-border-color);

            span,
            a {
                border: 0 none;
                text-align: center;
                display: block;
                padding: calc(var(--padding) * .85);

                border-radius: var-if(var(--enable-rounded-corners), calc(var(--rounded-corners-radius) / 2), 0px);

                &.p-default {
                    color: var(--input-font-color);
                }

                &.p-highlight {
                    background-color: var(--color-primary-color);
                    color: var(--color-primary-contrast);
                }

                &:hover {
                    background: rgba(0, 0, 0, .3);
                }
            }
        }

        tr:last-child td {
            border-bottom: 0 none;
        }
    }

    .p-timepicker {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 8px;

        color: var(--color-secondary-contrast);
        background: var(--color-secondary-color);

        border-bottom-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;
        border-bottom-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;

        .p-separator {
            a {
                display: none !important;
            }
        }

        .p-hour-picker,
        .p-minute-picker,
        .p-second-picker {
            text-align: center;

            >span {
                text-align: center;
                padding: 8px 0;
            }

            a {
                display: block;
                padding: 3px;
                @extend .icon;

                color: var(--color-secondary-contrast);

                &:hover {
                    text-decoration: none;
                }

                &:first-child,
                &:last-child {
                    font-size: 1rem;
                }

                &:first-child {
                    @extend .icon-chevron-up;
                }

                &:last-child {
                    @extend .icon-chevron-down;
                }
            }
        }
    }

    &.p-datepicker-timeonly .p-timepicker {
        background: var(--input-background-color);

        border-top-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;
        border-top-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;

        color: var(--input-font-color);

        .p-hour-picker button,
        .p-minute-picker button,
        .p-second-picker button {
            color: var(--input-font-color);
        }
    }

    .p-monthpicker {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        &-month {
            width: 100%;
            display: block;
            border: 0 none;
            text-align: center;
            padding: calc(var(--padding) * .85);

            border-radius: var-if(var(--enable-rounded-corners), calc(var(--rounded-corners-radius) / 2), 0px);

            &:hover {
                background: rgba(0, 0, 0, .3);
            }
            
            &.p-highlight {
                background-color: var(--color-primary-color);
                color: var(--color-primary-contrast);
            }
        }
    }

    .p-yearpicker {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        &-year {
            width: 100%;
            display: block;
            border: 0 none;
            text-align: center;
            padding: calc(var(--padding) * .85);

            border-radius: var-if(var(--enable-rounded-corners), calc(var(--rounded-corners-radius) / 2), 0px);

            &:hover {
                background: rgba(0, 0, 0, .3);
            }
            
            &.p-highlight {
                background-color: var(--color-primary-color);
                color: var(--color-primary-contrast);
            }
        }
    }
}
