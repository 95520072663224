.app-content {
    display: flex;
    height: 100%;
    flex-direction: column;

    background: var(--main-background-color);
    color: var(--main-font-color);

    * {
        border-color: var(--main-border-color);
    }

    @include media-breakpoint-up(md) {
        .app-menu ~ & {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {

        .app-menu ~ & {
            padding-left: var(--width-aside);


            .menu-collapsed & {
                padding-left: var(--width-aside-collapsed);
            }
        }

        .side-navigation-right.app-menu ~ & {
            padding-right: var(--width-aside);
            padding-left: 0;

            .menu-collapsed & {
                padding-right: var(--width-aside-collapsed);
                padding-left: 0;
            }
        }


    }
}


.app-content-body {
    //
    // See number 2 of this list:
    // https://hackernoon.com/11-things-i-learned-reading-the-flexbox-spec-5f0c799c776b
    //
    min-width: 0;
    order: 1;
    overflow: auto;
    flex-basis: 100%;
    @extend %mobile-scroll;

    // remove overflow crap in some z-indexing cases like modal and fullscreen map
    .disable-smooth-scrolling & {
        -webkit-overflow-scrolling: unset !important;
    }
}


.page-body {
    .page-card {
        background-color: var(--content-background-color);
    }
}


@include media-breakpoint-up(md) {

    .app-has-gutter {

        .page-body {
            overflow: none;
            padding: var(--main-gutter-width);
            box-shadow: inset var(--main-shadow); //0 2px 5px 0 rgba(0, 0, 0, 0.1);

            .page-card {
                box-shadow: var(--content-shadow); // 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                background-color: var(--content-background-color);
            }

        }

    }
}