@use 'sass:math';

//
// all dropdows related
//

.no-dropdown {
    .dropdown-toggle {
        display: none;
    }
}


kui-dropdown > .btn-group {
    cursor: pointer;

    .dropdown-toggle {
        display: inline-block;

        &:after {
            display: none;
        }
    }
}

.dropdown-menu {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    font-size: 1rem;
    max-height: 220px;
    min-width: 200px;
    overflow: auto;
    border: solid var(--input-border-width) var(--input-border-color);
    z-index: map-get($zindex, dropdown);

    * {
        border-color: var(--input-border-color);
    }

    @include overlay-dropshadow();

    background-color: var(--input-background-color);

    color: var(--input-font-color);

    &.dropdown-menu-right {
        left: unset;
        right: 0;
    }

    .dropdown-item {
        label {
            min-width: 0px;
        }

        color: var(--input-font-color);

        &:hover {
            color: var(--color-primary-contrast);
            background: var(--color-primary-color);
        }

        &.active,
        &:active {
            color: var(--color-primary-contrast) !important;
            background: var(--color-primary-color);
        }
    }
}

.open > .dropdown-menu, // related to multiselect dropdown
.show .dropdown-menu {
    opacity: 1;
    transition: opacity .15s .1s linear;
}

.hide > .dropdown-menu {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: 0;
}

// for some reason this does not work in bootstrap, so I added it
.dropup .dropdown-menu {
    top: unset;
    bottom: 100%;
}

.select-dropdown {

    input[type="checkbox"] {
        display: none;
    }

    .dropdown {
        width: 100%;
    }

    .caret {
        margin-left: auto !important;
    }

    .dropdown-menu {
        opacity: 1;
        min-width: 100%;
        position: absolute;
        top: unset;
    }

    .pull-right {
        right: 0;
        left: unset;
    }

    .search-container {

        .input-group-addon {
            display: none;
        }

        .input-group-prepend {
            padding-right: math.div($padding, 2);
            padding-right: calc(var(--padding)/2);
            align-self: center;
        }

        .input-group-btn button {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            @extend .btn-light;

            color: var(--main-font-color);

            border-width: $width-border !important;
            border-width: var(--width-border) !important;

            &:before {
                content: "\00D7";
            }
        }

        .form-control {
            border-top-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
            border-bottom-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
        }
    }

    .dropdown-item {
        a {
            @extend .dropdown-item;
            padding: 0 !important;
        }
    }

}

.input-group kui-select:not(:first-child) .dropdown .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group kui-select:not(:last-child) .dropdown .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0px !important;
}

// make z-index of dropdowns higher than the modals when a modal is open
.modal-ref ~ .dropdown-menu,
.modal-open .dropdown-menu {
    z-index: map-get($zindex, modal) + 10 !important;
}