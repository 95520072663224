@use 'sass:math';

$toggle-base-width: 3.5rem;
$toggle-base-height: 2rem;
$toggle-sizes-list: (
    (sm, 0.5, 2px), // ($classname, $multiplier, $padding)
    (md, 0.75, 2px), // default
    (lg, 1, 3px)
);

kui-toggle {
    display: block;
    position: relative;

    input {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        appearance: none;
        z-index: map-get($zindex, base);
        cursor: pointer;

        + .kui-toggle {
            width: 100%;
            height: 100%;
            position: absolute;
            transition: all .4s ease;

            background: var(--input-border-color);

            &:after,
            &:before {
                content: "";
                position: relative;
                display: block;
                width: 50%;
                height: 100%;
            }

            &:after {
                left: 0;
                background: var(--input-background-color);
                transition: all .2s ease;

                @if $enable-rounded-corners {
                    border-radius: 50%;
                }
                border-radius: var-if(var(--enable-rounded-corners), 50%, 0px);

            }

            &:before {
                display: none;
            }

            &.disabled {
                cursor: default !important;
                opacity: 0.75;

                &:after {
                    opacity: 0.5;
                }
            }
        }

        &:checked + .kui-toggle {
            background: var(--color-primary-color);

            &:after {
                left: 50%;
            }
        }
    }
}

// set specific styles for size classes sm, md, lg
@each $classname, $muliplier, $padding in $toggle-sizes-list {
    kui-toggle.#{$classname} {
        width: $toggle-base-width * $muliplier;
        height: $toggle-base-height * $muliplier;

        input {
            + .kui-toggle {
                padding: $padding;

                @if $enable-rounded-corners {
                    border-radius: (math.div($toggle-base-height, 3) * 2) * $muliplier;
                }
                border-radius: var-if(var(--enable-rounded-corners), (math.div($toggle-base-height, 3) * 2) * $muliplier, 0px);
            }
        }
    }
}
