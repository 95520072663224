//
// bootstrap config
//
// NOTE: The bootstrap overwrites can and should be changed with all the available variables in _config.scss.
//       DO NOT CHANGE below this point unless REALLY necessary.

//
// Color system
//
$theme-colors: (
  primary: $color-primary,
  secondary: $color-secondary,
  success: $color-success,
  info: $color-info,
  notify: $color-notify,
  warning: $color-warning,
  danger: $color-danger,
  light: $color-light,
  dark: $color-dark
);

//
// Options
//
$enable-shadows:            $enable-drop-shadows;
$enable-rounded:            $enable-rounded-corners;

//
// Spacing
//
$spacer:                    $padding;

//
// Body
//
// $body-bg:                   $color-bg-main;
// $body-color:                $color-text-default;

//
// Links
//
// $link-color:                $color-text-link;

//
// Grid columns
//
$grid-gutter-width:         $padding;

//
// Components
//
$border-width:              $width-border;
// $border-color:              $color-border;

$border-radius:             $rounded-corners-radius;
$border-radius-lg:          $rounded-corners-radius;
$border-radius-sm:          $rounded-corners-radius;

// $component-active-color:    $color-primary-contrast;

//
// Fonts
//
$font-family-sans-serif: if($font-sans-serif, map-deep-get($fonts-sans-serif-map, $font-sans-serif, font-family), ''), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
$font-family-monospace: if($font-monospace, map-deep-get($fonts-monospace-map, $font-monospace, font-family), ''), "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;


$font-size-base:            rem-calc($font-size-default, 16);
$font-size-lg:              rem-calc($font-size-for-lg, 16);
$font-size-sm:              rem-calc($font-size-for-sm, 16);

$h1-font-size:              $font-size-base * 2.5;
$h2-font-size:              $font-size-base * 2;
$h3-font-size:              $font-size-base * 1.75;
$h4-font-size:              $font-size-base * 1.5;
$h5-font-size:              $font-size-base * 1.25;
$h6-font-size:              $font-size-base * 1;

// $text-muted:                rgba($body-color, .5);

//
// Tables
//
//$table-bg:                  $color-bg-table;
//$table-border-color:        $color-bg-table-border;

//
// Pagination
//
// $pagination-border-color:   $color-border;
// $pagination-disabled-border-color: $color-border;

//
// Cards
//
// $card-bg:                   $color-bg-card-block;

//
// Modals
//
// $modal-content-bg:          $color-bg-card-block;

$font-weight-normal:        400;
$font-weight-bold:          600;

//
// List group
//
// $list-group-bg:                     $color-bg-header;
// $list-group-border-color:           $color-bg-header-border;

//
// Badges
//
$badge-padding-y:                   $padding * .35;
$badge-padding-x:                   $padding * .4;

//
// Breakpoints
// This is so that the breakpoint mixins can be used on external projects without accessing it directly from bootstrap
//
@import "../utilities/breakpoints";

/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/print";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/utilities";

// currently unnused
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";

