
.border-radius {
    border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
}

.border-round {
    border-radius: var-if(var(--enable-rounded-corners), 50%, 0px);
}

.border-color {
    border-color: var(--main-border-color);
}

@each $color, $value in $theme-colors {
    .border-#{$color} {
        border-color: $value !important;
        border-color: var(--color-#{$color}-color) !important;
    }
}

.border-width {
  border-width: var(--width-border);
}

.border-width-more {
  border-width: calc(var(--width-border) * 4);
}

.border {
    border-style: solid;
    border-width: var(--width-border);
}

// remove borders
// some places has double borders because of the combination of components. These helper classes removes those borders
// eg:
// * <div class="no-border-top"></div> - removes border on assigned div
// * <div class="no-border-left-nested"></div> - removes border on direct child of assigned div
$positions-list: top bottom right left;
$breakpoints-list: sm md lg xl;

@mixin no-boders-breakpoints($position, $breakpoint) {
  @include media-breakpoint-up($breakpoint) {
    .no-border-#{$breakpoint}-#{$position} {
      border-#{$position}-style: none !important;
    }

    .no-border-#{$breakpoint} {
      border-style: none !important;
    }

    .no-border-#{$breakpoint}-#{$position}-nested {
      > * {
        border-#{$position}-style: none !important;
      }
    }

    .no-border-#{$breakpoint}-nested {
      > * {
        border-style: none !important;
      }
    }
  }
}

@each $position in $positions-list {
  .no-border-#{$position} {
    border-#{$position}-style: none !important;
  }

  .no-border {
    border-style: none !important;
  }

  .no-border-#{$position}-nested {
    > * {
      border-#{$position}-style: none !important;
    }
  }

  .no-border-nested {
    > * {
      border-style: none !important;
    }
  }

  .border-#{$position} {
    border-#{$position}-style: solid !important;
    border-#{$position}-width: $width-border !important;
    border-#{$position}-width: var(--width-border) !important;
  }

  @each $breakpoint in $breakpoints-list {
    @include no-boders-breakpoints($position, $breakpoint);
  }
}
