//
// position
//
$position-list: relative absolute fixed;
@include property-list-loop(position, $position-list);

//
// overflow
//
$overflow-list: hidden visible auto scroll;
@include property-list-loop(overflow, $overflow-list, true);
.overflow-scroll {
    // adds smooth scrolling on mobile
    -webkit-overflow-scrolling: touch;

    // remove overflow crap when modal is open
    .disable-smooth-scrolling & {
        -webkit-overflow-scrolling: unset !important;
    }
}

//
// disabled/muted class :: color agnostig
//
.disabled,
.disabled:hover,
.disabled:active {
    opacity: .6 !important;
    cursor: default;
}

.muted {
    opacity: .6 !important;
}

.muted-less {
    opacity: .8 !important;
}

.muted-more {
    opacity: .4 !important;
}

.icon-light {
    color: #ddd; 
}

//
// text
//

.text-link {
    color: var(--color-primary-color);
    cursor: pointer;

    &:hover {
        opacity: .9;
        text-decoration: underline;
        @include var-darken-text(var(--color-primary-color), .15);
    }

    &.base-color {
        color: var(--main-font-color);

        &:hover {
            opacity: .9;
        }
    }
}

//
// visiblity hidden
//
.visibility-hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
    appearance: none;
}

.hidden {
    display: none !important;
}


.loading-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--loader-overlay-background-color);
    z-index: map-get($zindex, overall);
}

.min-width-0 {
    min-width: 0;
}

.justify-right {
    justify-content: right;
}