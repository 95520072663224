kui-menu-brand {
    z-index: map-get($zindex, base);
    border-color: var(--navigation-logo-border-color) !important;

    .brand {
        padding: $padding 0;
        padding: var(--padding) 0;

        text-align: center;

        background-color: var(--navigation-logo-background-color);

        .name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }

        img {
            width: auto;
            max-width: 120px;
        }


        &:hover {
            text-decoration: none;
        }

        border-bottom-style: solid;
        border-bottom-width: var(--navigation-border-width);
    }



}