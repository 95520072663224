//
// flexbox helper classes
//

.flex-stretch {
    flex: 1 1 auto;
    min-width: 1px; // helps with overflows where the content doesn't adhere the the container ratio
    min-height: 1px; // helps with overflows where the content doesn't adhere the the container ratio
}

.flex-contracted {
    flex: 0 0 auto;
}

.flex-grow-0 {
    flex-grow: 0.001;
}

@for $i from 1 through 3 {
  .flex-#{$i} {
    flex: $i;
    min-width: 1px; // helps with overflows where the content doesn't adhere the the container ratio
    min-height: 1px; // helps with overflows where the content doesn't adhere the the container ratio
  }
}

.flex-center-children {
    display: flex;
    justify-content: center;
    align-items: center;
}

// frequently used bootstrap flexbox utility classes that makes a container stretch
%flex-container {
    @extend .flex-1;
    @extend .flex-column;
}

.flex-container {
    @extend %flex-container;
    @extend .d-md-flex;

    &.apply-deep * {
        @extend %flex-container;
        @extend .d-md-flex;
    }
}

.flex-container-mobile {
    @extend %flex-container;
    @extend .d-flex;
}

.flex-scrollable {
    @extend .flex-1;
    @extend .overflow-auto;
}