.assetfilter-popup {

    .dropdown-menu {
        max-height: unset;
        max-width: unset;
        overflow: unset;
        padding: 0;
        min-width: 25rem;
    }


    .dropdown-content {
        overflow: auto;
    }

    kui-tabs {
        padding-bottom: unset;

        .nav.nav-tabs {
            padding-top: var(--padding);
            padding-left: var(--padding);
        }

        .tab-container {
            border: unset;
        }

        .tab.active {
            overflow: auto;
            margin: 0;

            .asset-list {
                overflow: unset;
            }
        }
    }
}