//
// icon fonts
//
@mixin fontface($family, $varient, $weight) {
    @font-face {
        font-family: if($family, $family, 'Font Awesome 6 Pro');
        font-style: normal;
        font-weight: $weight;
        src: url('/assets/fonts/fa-#{$varient}-#{$weight}.eot');
        src: url('/assets/fonts/fa-#{$varient}-#{$weight}.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/fa-#{$varient}-#{$weight}.woff2') format('woff2'),
        url('/assets/fonts/fa-#{$varient}-#{$weight}.woff') format('woff'),
        url('/assets/fonts/fa-#{$varient}-#{$weight}.ttf') format('truetype'),
        url('/assets/fonts/fa-#{$varient}-#{$weight}.svg#fontawesome') format('svg');
    }
}

// add fontawesome base styles to any selector
@mixin icon($weight) {
    font-family: 'Font Awesome 6 Pro' !important;
    line-height: 100%;

    @if $weight {
        font-weight: $weight;
    }
    @else {
        font-weight: map-get($icons-weight-map, $icon-weight);
        font-weight: var(--icon-weight);
    }
}

//
// custom svg icons
//

// these are icons used inside the settings sidebar like form builder/viewer
// and the main purpose is to change the color according to the theme
.settings-icon {
    fill: var(--main-font-color);
    stroke: var(--main-font-color);

    &:hover {
        opacity: .8;
    }

    .selected & {
        fill: var(--color-primary-color);
        stroke: var(--color-primary-color);
        cursor: default;

        &:hover {
            opacity: 1;
        }
    }
}
