// css varaible replacements

@each $color, $value in $theme-colors {
    .badge-#{$color} {
        color: var(--color-#{$color}-contrast);
        background-color: var(--color-#{$color}-color);
    }
}

.badge.clickable:hover {
    cursor: pointer;
}