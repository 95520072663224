$fa-css-prefix: icon;
@import '@fortawesome/fontawesome-pro/scss/fontawesome';

@include fontface(null, 'solid', 900);
@include fontface(null, 'regular', 400);
@include fontface(null, 'light', 300);

kui-icon {
    line-height: 100%;
    display: inline-block !important;
}

.icon {
    @include icon(null);

    &__fill {
        @include icon(map-get($icons-weight-map, solid));
    }
}

// // https://www.s-ings.com/typicons/more/how-to-use-typicons
// @import 'typicons.font/src/font/typicons';

// @font-face {
//     font-family: 'typicons';
//     src: url("/assets/fonts/typicons.eot");
//     src: url("/assets/fonts/typicons.eot?#iefix") format('embedded-opentype'),
//          url("/assets/fonts/typicons.woff") format('woff'),
//          url("/assets/fonts/typicons.ttf") format('truetype'),
//          url("/assets/fonts/typicons.svg#typicons") format('svg');
//     font-weight: normal;
//     font-style: normal;
//   }