//
// iterations
//

// utility when writing helper classes of the same property with different values
// eg. property-list-loop(position, relative absolute fixed) = .position-relative { position: relative; }  ...
@mixin property-list-loop($property, $list, $important: null) {
  @each $item in $list {
    .#{$property}-#{$item} { #{$property}: $item if($important, !important, null); }
  }
}


@mixin overlay-dropshadow($important: false) {
    box-shadow: 0 3px 10px rgba-emit(calc(var(--drop-shadows-opacity) * 1.5)) if($important, !important, null);
}
