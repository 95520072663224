key-leaflet-map {
    display: flex;
    height: 100%;
    position: relative;
}

.map-font-icon {
    i {
        font-size: 1.7rem;
    }

    &.selected {
        i {
            font-size: 2rem;
        }
    }

    // always layer the actual icon on top of the background shape
    i:not(&__background) {
        position: relative;
        z-index: map-get($zindex, one);
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
    }

    &__shadows {
        text-shadow: -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff,
            1px 1px 3px #000;
    }
}

.leaflet-control-layers-expanded {
    background: var(--map-control-background-color) !important;
    .leaflet-control-layers-list {
        color: var(--map-control-font-color) !important;
    }
}

// style all leaflet control items the exact same
// hence all the !important statements
.leaflet-control {
    @if $enable-rounded-corners {
        border-radius: $rounded-corners-radius !important;
    }

    border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;

    a {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 30px !important;
        height: 30px !important;
        font-size: 0 !important;
        background-image: none !important;


        border-color: var(--content-border-color);
        background: var(--content-background-color) !important;

        &:before {
            @extend .icon;
            font-size: .95rem;
            background-image: none !important;
            color: var(--map-control-font-color);
        }

        &:first-of-type {
            @if $enable-rounded-corners {
                border-top-right-radius: $rounded-corners-radius - ($width-border * 2) !important;
                border-top-left-radius: $rounded-corners-radius - ($width-border * 2) !important;
            }

            // When rounded-corners is enabled, we need to compensate for border width of the parent,
            // so because the parent border is (2 * $width-border) we have to subtract that from the radius
            border-top-right-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - calc(var(--width-border) * 2))"), 0px) !important;
            border-top-left-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - calc(var(--width-border) * 2))"), 0px) !important;
        }

        &:last-of-type {
            @if $enable-rounded-corners {
                border-bottom-right-radius: $rounded-corners-radius - ($width-border * 2) !important;
                border-bottom-left-radius: $rounded-corners-radius - ($width-border * 2) !important;
            }

            // same as above
            border-bottom-right-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - calc(var(--width-border) * 2))"), 0px) !important;
            border-bottom-left-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - calc(var(--width-border) * 2))"), 0px) !important;
        }

        &.leaflet-disabled {
            background: var-darken(var(--map-control-background-color), .1) !important;

            &:before {
                opacity: .4;
            }
        }

        &:hover {
            background: var-darken(var(--map-control-background-color), .15) !important;
        }
    }
}

.leaflet-control-layers,
.leaflet-bar {
    border: var(--width-border) solid var(--content-border-color) !important;
    box-shadow: 0 2px 5px rgba-emit(var(--drop-shadows-opacity)) !important;
}

// assigning the actual icons to respective controls
.leaflet-control-layers-toggle {
    .leaflet-control-layers-expanded & {
        display: none !important;
    }

    &:before {
        // layers icon does not exist so we use a clone icon with some transforms
        @extend .icon-clone;
        transform: rotate(-55deg) skew(6deg, 20deg) scale(1, 1.1) translate(0, 1px);
    }
}

.reset-control a:before {
    @extend .icon-crosshairs;
}

.leaflet-control-fullscreen a:before {
    @extend .icon-expand;
}

.leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a:before {
    @extend .icon-compress;
}

.leaflet-control-zoom-in:before {
    @extend .icon-plus;
}

.leaflet-control-zoom-out:before {
    @extend .icon-minus;
}

.leaflet-draw-draw-polygon:before {
    @extend .icon-draw-polygon;
}

.leaflet-draw-draw-polyline:before {
    @extend .icon-route;
}

.leaflet-draw-edit-edit:before {
    @extend .icon-edit;
}

.leaflet-draw-edit-remove:before {
    @extend .icon-trash;
}

.leaflet-draw-actions {
    a {
        @extend .font-sans-serif;
        font-size: inherit !important;
        width: auto !important;
        color: var(--map-control-font-color) !important;
        border-left: 1px solid var(--content-border-color) !important;
    }
}