.info-display {
    table {

        td {
            vertical-align: top;

            &:first-child {
                white-space: nowrap;
                padding-right: 0.8rem;
                text-align: right;
                border-right: solid var(--width-border) rgba(0, 0, 0, .05);
                text-transform: uppercase;
                opacity: 0.6;
                font-size: 0.8125rem;
                padding-top: 3px;
                min-width: 80px;

            }

            &:last-child {
                padding-left: 0.8rem;
                word-break: break-word;

                img {
                    max-width: 150px;
                }

                span.units {
                    opacity: 0.6;
                    font-size: 0.9rem;
                    padding-left: 0.4rem;
                }

            }
        }

    }
}