
@mixin active-element() {
    color: var(--table-active-font-color);
    background: var(--table-active-background-color);
}


@mixin rounded-corners() {
    @if $enable-rounded-corners {
        border-top-left-radius: $rounded-corners-radius;
    }
    border-top-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
}