//
// header position
//

@mixin position-inline-navbar {
    flex-direction: row;
    align-items: center;

    kui-header-nav {
        margin-left: auto;
    }
}
