@use 'sass:math';
@import '../functions/decimal';
@import '../functions/deep-get-map';
@import '../functions/colors';

//
// theme settings
//

// overall
$enable-drop-shadows: true !default;
$drop-shadows-opacity: .25 !default;
$enable-rounded-corners: true !default;
$rounded-corners-radius: 10px !default;

// menu
//$menu-top: false !default;

// navigation
$navigation-active-style: 'background' !default; // OPTIONS: 'background', 'border-left', 'border-right'

// POSITION OPTIONS:
//      'flow' - flows naturally into place as the window gets smaller
//      'stretch' - similar to flow, but stretches to fit a set amount of elements per row depending on screen size
//      'inline-title' - moves to the same row as title on smaller screens
$header-content-nav-position: 'stretch' !default;
$header-buttons-small: true !default;

// TEXT OPTIONS:
//      'never' - show text on any screen size
//      'always' - hide text on any screen size
//      'on-mobile' - hide text on mobile only
//      'on-desktop' - hide text on desktop only
$header-links-text-hide: 'never' !default; // ** TODO ** - should update sidebar to handle this feature

// brand
$brand-align: center !default; // OPTIONS: left, right, center

//
// widths
//

$width-aside: 14.75rem !default;
$width-aside-collapsed: 4rem !default;

$width-border: 1px !default;

//
// heights
//

$height-header: 4rem !default; // 64px

//
// margins and padding
//

$padding: 1rem !default;

//
// opacity
//

$opacity-modal-backdrop: 0.5 !default;

//
// scrollbars
//
$scrollbar-track-color: rgba(0, 0, 0, .01) !default;
$scrollbar-handle-color: rgba(0, 0, 0, .1) !default;
$scrollbar-handle-padded: true !default;

//
// colors
//

// these need to stay around for a while as a number of SASS functions depend on them
$color-primary: #0275d8 !default;
$color-secondary: rgb(45, 53, 60) !default;
$color-success: #5cb85c !default;
$color-info: #5bc0de !default;
$color-notify: #f9f6e8 !default;
$color-warning: #f0ad4e !default;
$color-danger: #d9534f !default;
$color-light: #F5F5F5 !default;
$color-dark: #55595c !default;


// $color-white: #ffffff !default;

// $color-gray-dark: #373a3c !default;
// $color-gray: #55595c !default;
// $color-gray-light: #818a91 !default;
// $color-gray-lighter: #eceeef !default;
// $color-gray-lightest: #f7f7f9 !default;

// $color-primary: #0275d8 !default;
// $color-primary-contrast: #ffffff !default;
// $color-secondary: rgb(45, 53, 60) !default;
// $color-secondary-contrast: color-contrast($color-secondary) !default;

// $color-success: #5cb85c !default;
// $color-success-contrast: color-contrast($color-success) !default;
// $color-info: #5bc0de !default;
// $color-info-contrast: color-contrast($color-info) !default;
// $color-notify: #f9f6e8 !default;
// $color-notify-contrast: color-contrast($color-notify) !default;
// $color-warning: #f0ad4e !default;
// $color-warning-contrast: color-contrast($color-warning) !default;
// $color-danger: #d9534f !default;
// $color-danger-contrast: color-contrast($color-danger) !default;

// $color-border: rgba(0, 0, 0, .125) !default;

// $color-light: #F5F5F5 !default;
// $color-light-contrast: color-contrast($color-light) !default;
// $color-dark: #55595c !default;
// $color-dark-contrast: color-contrast($color-dark) !default;

// $color-click-animation: #999999 !default;

// $color-bg-main: $color-gray-lightest !default;
// $color-bg-main-contrast: color-contrast($color-bg-main) !default;
// $color-bg-main-border: $color-border !default;

// $color-bg-body: $color-white !default;

// $color-bg-menu: $color-secondary !default;
// $color-bg-menu-contrast: color-contrast($color-bg-menu) !default;
// $color-bg-menu-border: $color-border !default;

// $color-bg-menu-accent: #ffffff !default;
// $color-bg-menu-accent-contrast: color-contrast($color-bg-menu-accent) !default;

// $color-bg-snackbar: #ffffff !default;
// $color-bg-snackbar-contrast: color-contrast($color-bg-snackbar) !default;

// $color-bg-header: $color-bg-menu !default;
// $color-bg-header-contrast: color-contrast($color-bg-header) !default;
// $color-bg-header-border: $color-bg-menu-border !default;
// $color-bg-header-accent: $color-bg-menu-accent !default;
// $color-bg-header-accent-contrast: color-contrast($color-bg-header-accent) !default;
// $color-bg-header-active: color-contrast($color-bg-header, darken($color-bg-header, 4%), lighten($color-bg-header, 4%)) !default;
// $color-bg-header-active-contrast: $color-bg-header-contrast !default;
// $color-bg-header-active-border: $color-border !default;

// $color-bg-brand: $color-bg-main !default;
// $color-bg-brand-border: $color-bg-menu-border !default;

// $color-bg-card-header: #fcfcfc !default;
// $color-bg-card-header-contrast: color-contrast($color-bg-card-header) !default;
// $color-bg-card-header-border: $color-border !default;
// $color-bg-card-block: $color-white !default;
// $color-bg-card-block-contrast: color-contrast($color-bg-card-block) !default;
// $color-bg-card-block-border: $color-border !default;
// $color-bg-card-footer: #fcfcfc !default;
// $color-bg-card-footer-contrast: color-contrast($color-bg-card-footer) !default;
// $color-bg-card-footer-border: $color-border !default;

// $color-bg-table: $color-bg-header !default;
// $color-bg-table-contrast: color-contrast($color-bg-table) !default;
// $color-bg-table-border: rgba($color-border, .5) !default;
// $color-bg-table-header: $color-bg-header !default;
// $color-bg-table-header-contrast: color-contrast($color-bg-table-header) !default;
// $color-bg-table-header-border: rgba($color-border, .5) !default;

// $color-bg-list-active: $color-bg-menu-accent !default;
// $color-bg-list-active-contrast: color-contrast($color-bg-list-active) !default;
// $color-bg-hover: $color-bg-menu-accent !default;
// $color-bg-hover-contrast: color-contrast($color-bg-hover) !default;

// $color-text-default: $color-secondary !default;
// $color-text-link: $color-primary !default;
// $color-text-inverse: #ffffff !default;

// $color-bg-list-group-default: $color-bg-card-block !default;

// $color-bg-pagination: $color-bg-card-block !default;
// $color-bg-pagination-border: $color-bg-card-block-border !default;

// $color-bg-dropdown-menu: $color-bg-card-block !default;
// $color-bg-dropdown-menu-contrast: color-contrast($color-bg-dropdown-menu) !default;
// $color-bg-dropdown-menu-border: $color-bg-card-block-border !default;
// $color-bg-dropdown-header: $color-bg-header-active !default;
// $color-bg-dropdown-header-contrast: color-contrast($color-bg-dropdown-header) !default;

// $color-bg-tooltip: $color-bg-card-block !default;
// $color-bg-tooltip-contrast: color-contrast($color-bg-tooltip) !default;

// $color-bg-inputs: $color-white !default;
// $color-bg-inputs-contrast: color-contrast($color-bg-inputs) !default;
// $color-bg-inputs-border: $color-border !default;
// $color-bg-toggle: #eceeef !default;
// $color-toggle: #ffffff !default;

// $color-bg-map-controls: $color-bg-card-block !default;
// $color-bg-map-controls-contrast: color-contrast($color-bg-map-controls) !default;
// $color-bg-map-controls-border: $color-border !default;

// $color-loader: #666 !default;
// $color-loader-overlay: rgba(255, 255, 255, .6) !default;

//
// fonts
//

// By default the native system font is used for sans-serif
$font-sans-serif: false !default; // OPTIONS: available fonts in fonts/maps -> $fonts-sans-serif-map.

// By default the native system font is used for monospace
$font-monospace: false !default; // OPTIONS: available fonts in fonts/maps -> $fonts-monospace-map

$keep-default-font-size: false !default;
$font-size-default: 16 !default; // should be desired size in px
$font-size-for-lg: if($keep-default-font-size, $font-size-default, $font-size-default * 1.25) !default;
$font-size-for-sm: if($keep-default-font-size, $font-size-default, $font-size-default * .875) !default;

$icon-font-size: 14px !default;
$icon-line-height: 1 !default;

$icon-weight: 'solid' !default; // OPTIONS: 'solid', 'regular', 'light'
$fa-font-path: '@fortawesome/fontawesome-pro/webfonts/' !default;

//
// convenience helpers
//

$height-header-inner: decimal-ceil(($height-header - (math.div($height-header, $font-size-default))), 2); // NOTE: helps vertical alignment of content inside header
//$menu-position: if($menu-top, 'top', 'left');
//$menu-position: 'right';
