// typography
a {
    cursor: pointer;
    color: var(--color-primary-color);
}

a:not([href]):not([tabindex]) {
    color: var(--color-primary-color);
}

a:not([href]):not([tabindex]):focus {
    outline: none;
}

// %navbar-brightness: replaces .navbar-light and .navbar-dark

%navbar-brightness {
    color: var(--header-font-color);
    display: block;

    .active {
        color: var(--header-font-color);
    }

    .navbar-brand {
        color: var(--header-font-color);

        @include hover-focus {
            color: var(--header-font-color);
        }
    }

    .navbar-nav {
        .nav-link {
            color: var(--header-font-color);
            opacity: .7;

            @include hover-focus {
                color: var(--header-font-color);
                opacity: .9;
            }
        }

        .open > .nav-link,
        .active > .nav-link,
        .nav-link.open,
        .nav-link.active {
            @include plain-hover-focus {
                color: var(--header-font-color);
            }
        }
    }

    .navbar-divider {
        background-color: rgba(0, 0, 0, .075);
    }
}

// Bootstrap does not add units for .btn
.btn {
    font-size: #{$font-size-default}px;
    .input-group-btn & {
        background-clip: initial;
    }
}

// Overwrite the table active and hover to stay consistent with the theme
.table-active,
.table-active > th,
.table-active > td {
    color: var(--color-primary-contrast);
    background: var(--color-primary-color);
}

.table-hover-state:hover,
.table-hover tbody tr:hover {
    color: var(--color-primary-color);
}

h5 {
    font-weight: 500;
    font-size: 1.1rem;
}

b {
    font-weight: 500;
}

.input-group-addon {
    padding-top: 0;
    padding-bottom: 0;
}

// layout
.input-group-addon,
.input-group-btn,
.input-group .form-control,
.input-group kui-datetime .p-datepicker.p-component .p-datepicker-header .p-datepicker-title select,
kui-datetime .p-datepicker.p-component .p-datepicker-header .p-datepicker-title .input-group select {
    display: flex !important;
}

.form-control {
    box-shadow: none !important;
}

.card-footer {
    padding: 1.25rem;
}


//
// css variable replacements
//
// border-radius
//
.alert,
.badge,
.badge-pill,
.breadcrumb,
.btn,
.btn-lg,
.btn-sm,
.card,
.card-img,
code,
kbd,
.custom-control-indicator,
.custom-radio .custom-control-indicator,
.custom-select,
.custom-file-control,
.dropdown-menu,
.form-control,
.form-control-sm,
.form-control-lg,
.img-thumbnail,
.input-group-addon,
.input-group-addon.form-control-sm,
.input-group-addon.form-control-lg,
.jumbotron,
.modal-content,
.nav-pills .nav-link,
.navbar-toggler,
.pagination,
.pagination-sm,
.pagination-lg,
.popover,
.progress,
.tooltip-inner {
    border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
}

.badge {
    padding: 5px 8px;
    font-size: 100%;
    font-weight: 400;
}

// border-top-radius
.card > .list-group:first-child .list-group-item:first-child,
.card-img-top,
.list-group-item:first-child,
.card-header:first-child,
.nav-link,
.btn-group-vertical > .btn:first-child {
    border-top-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;
    border-top-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;
}

// border-bottom-radius
.card > .list-group:last-child .list-group-item:last-child,
.card-img-bottom,
.card-footer:last-child,
.list-group-item:last-child,
.btn-group-vertical > .btn:last-child {
    border-bottom-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;
    border-bottom-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) !important;
}

// border-left-radius
.page-item:first-child .page-link {
    border-top-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
    border-bottom-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
}

// border-right-radius
.page-item:last-child .page-link {
    border-top-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
    border-bottom-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
}

.page-item {
    border: none !important;
}

.input-group .form-control:not(:last-child),
.input-group kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title select:not(:last-child),
kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title .input-group select:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group .form-control:not(:first-child),
.input-group kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title select:not(:first-child),
kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title .input-group select:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

//
// box shadow
//
.popover {
    @include overlay-dropshadow();
}

// dropping box shadows for now until safari knows how to do calc() inside rgba()
// or we can find another solution to do this with
//
// .btn-group.show .dropdown-toggle:not(.btn-link) {
//     box-shadow: inset 0 3px 5px rgba-emit(
//         calc(calc(var(--drop-shadows-opacity) * .5) * var(--enable-drop-shadows)));
// }
// kbd {
//     box-shadow: inset 0 -.1rem 0 rgba-emit(calc(var(--drop-shadows-opacity) * var(--enable-drop-shadows)));
// }
// .custom-control-input {
//     &:focus ~ .custom-control-indicator {
//         box-shadow: 0 0 0 calc(1px + var(--enable-drop-shadows)) var(--color-bg-body), 0 0 0 calc(3px * var(--enable-drop-shadows)) var(--color-primary-color);
//     }
// }
// .custom-control-indicator {
//   box-shadow: inset 0 .25rem .25rem rgba-emit(calc(var(--drop-shadows-opacity) * var(--enable-drop-shadows)));
// }
// .custom-select {
//     &:focus {
//         box-shadow: inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5);
//     }
// }
// .custom-file-input {
//   &:focus ~ .custom-file-control {
//     box-shadow:
//         0 0 0 .075rem rgba-emit(var(--enable-drop-shadows), 255, 255, 255),
//         0 0 0 calc(.25rem * var(--enable-drop-shadows)) var(--color-primary-color);
//   }
// }
// .custom-file-control {
//   box-shadow: inset 0 1px 1px rgba-emit(calc(var(--drop-shadows-opacity) * var(--enable-drop-shadows)));
// }
// .form-control {
//     box-shadow: inset 0 1px 1px rgba-emit(
//         calc(
//             calc(var(--drop-shadows-opacity) * .5) * var(--enable-drop-shadows)));
// }
// .img-thumbnail {
//     box-shadow: 0 1px 2px rgba-emit(calc(var(--drop-shadows-opacity) * var(--enable-drop-shadows)));
// }
// @include media-breakpoint-up(sm) {
//     .modal-content {
//         box-shadow: 0 5px 15px rgba-emit(calc(var(--drop-shadows-opacity) * var(--enable-drop-shadows)));
//     }
// }

//
// success and error form field states
//
.was-validated .form-control:valid,
.was-validated kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title select:valid,
kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title .was-validated select:valid,
.form-control.is-valid,
kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title select.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: var(--color-success-color) !important;
}

.was-validated .form-control:invalid,
.was-validated kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title select:invalid,
kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title .was-validated select:invalid,
.form-control.is-invalid,
kui-datetime .p-datepicker.ui-widget .p-datepicker-header .p-datepicker-title select.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: var(--color-danger-color) !important;
}