$theme: 'default';

$navigation-active-style: 'border-left';

$height-header: 3.5rem;


$font-size-default: 13;
$font-sans-serif: ubuntu;

$enable-drop-shadows: true;
$enable-rounded-corners: false;

$icon-weight: 'solid';
$fa-font-path: '.';

@import 'assets/scss/key-ui/base/application.scss';
@import 'assets/scss/app';
@import 'assets/scss/key-ui/utilities/breakpoints';

/* change the map maker icon color */
:host ::ng-deep {
    .marker-cluster div {
        color: #FFF;
    }
}

#ie-must-die {
    display: none !important;
}

// show message to <= ie10 users that they are not allowed into the software
.ie-old {
    #loader {
        display: none !important;
    }

    #ie-must-die {

        display: block !important;
        top: 50%;
        position: absolute;
        left: 50%;
        margin-left: -170px;
        margin-top: -165px;
        width: 340px;

        .btn-primary,
        .btn-primary:hover {
            background: #f33636 !important;
            border-color: #f33636 !important;
            color: #fff !important;
        }
    }
}
