kui-datetime {

    p-calendar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .p-calendar {
        position: unset;
        display: unset;
    }

    .p-inputtext {
        width: 1px !important;
        opacity: 0;
    }

    .p-button,
    .native-input {
        opacity: 0;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: map-get($zindex, base);
    }

    .focussed {
        // only animate the click to open the date picker, not every click inside of it too
        .action__container {
            display: none !important;
        }
    }

    .hover:hover {
        opacity: 1;

        ~.input-group {
            opacity: .8;
        }
    }
}

.kui-datetime.p-datepicker.p-component {
    padding: 0;
    position: absolute;
    left: 0;
    z-index: map-get($zindex, modal) !important;
    float: left;
    min-width: 160px;
    margin: 2px 0 0;
    font-size: 12px;
    text-align: left;
    list-style: none;
    background-clip: padding-box;

    background-color: var(--input-background-color) !important;
    border: var(--width-border) solid var(--input-border-color);
    border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);

    @include overlay-dropshadow();
    @include calendar();

}


