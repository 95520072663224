@use 'sass:math';

.user-profile {
    white-space: nowrap;

    padding: var(--padding);

    background: var(--navigation-client-background-color);
    color: var(--navigation-client-font-color);

    border-bottom-style: solid;
    border-bottom-width: var(--width-border);
    border-color: var(--navigation-client-border-color);


    @if $enable-rounded-corners {
        border-radius: 0px 0px $rounded-corners-radius $rounded-corners-radius;
    }

    border-radius: 0px 0px var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px) var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);

    .profile-image-container {
        display: none;
    }


    .user-profile-button {
        &:after {
            // NOTE: bootstrap overwrite
            position: absolute;
            top: 50%;
            margin-top: -2px;
        }
    }

    .user-profile-menu {
        left: $padding;
        left: var(--padding);

        right: $padding;
        right: var(--padding);
    }

    .profile-image {
        max-width: 30px;
        max-height: 30px;
        vertical-align: middle;
        border-radius: 50%;
    }

    .profile-image-text {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
    }

    .profile-info {
        text-align: center;
        line-height: 1;
    }

    kui-dropdown-custom {
        line-height: initial;

        .profile-info {
            margin-top: math.div($padding, 2);
            margin-top: calc(var(--padding) / 2);
        }
    }

    @include media-breakpoint-up(md) {
        .menu-collapsed & {
            @include user-collapsed-sidebar();
        }
    }
}