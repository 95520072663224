kui-tooltip,
.tooltip {
    white-space: nowrap;

    .tooltip-inner {
        background: var(--input-background-color) !important;
        color: var(--input-font-color) !important;
    }

    &.tooltip-align-left {
        left: 0;
    }

    &.tooltip-align-right {
        right: 0;
    }
}
