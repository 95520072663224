//
// css varaible replacements
//

.list-group-item {

    border-width: var(--width-border);
    // background-color: var(--list-background-color);
    background-color: unset;
    color: var(--list-font-color);

    &.disabled,
    &:disabled {
        @extend .muted;
    }

    &.active {
        color: var(--color-primary-contrast);
        background-color: var(--color-primary-color);
    }
}

@each $color, $value in $theme-colors {
    .list-group-item-#{$color} {
        background: var-lighten(var(--color-#{$color}-color));

        .list-item-container,
        .list-group-item-heading,
        .list-group-item-text {
            @include var-darken-text(var(--color-#{$color}-color), 5);
        }
    }

    a.list-group-item-#{$color},
    button.list-group-item-#{$color} {
        .list-item-container,
        .list-group-item-heading,
        .list-group-item-text {
            @include var-darken-text(var(--color-#{$color}-color), 5);
        }

        &:focus,
        &:hover {
            color: var(--color-#{$color}-color);
            background: var-lighten(var(--color-#{$color}-color));
        }

        &.active {
            color: var(--color-#{$color}-contrast);
            background-color: var(--color-#{$color}-color);
            border-color: var(--color-#{$color}-color);
        }
    }
}
