:root {
    --loader-font-color: #666;
}

//
// startup loader container
//
#loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: sans-serif;
    font-size: 1rem;
    color: #444;
    opacity: 1;
    background: #fff;
    z-index: 999999;
    transition: opacity 1s ease;

    &.fade {
        opacity: 0;
    }

    &.hide {
        display: none;
    }

    .loader {
        width: 40px;
        height: 40px;
        display: inline-block;
        position: relative;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    .inner {
        animation: spinnerRotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: spinnerDash 1.5s ease-in-out infinite;
        stroke-linecap: round;
        stroke: var(--loader-font-color);
    }

    @keyframes spinnerRotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes spinnerDash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }

        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
}