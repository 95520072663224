.action {
  position: relative;
  display: inherit;
  cursor: pointer;

  &__container {
    display: none;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0;
    left: 0;
    z-index: map-get($zindex, overall);
    pointer-events: none;
  }

  &__pulse {
    width: 100%;
    height: 100%;
    background-color: var(--color-click-animation);
    border-radius: 100%;
    transform: scale(0);
    opacity: 1;
  }

  &--animate {
    .action__container {
      display: block;
    }

    .action__pulse {
      display: block;
      animation: action-pulseScaleOut .6s 1;
    }
  }
}

@keyframes action-pulseScaleOut {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
