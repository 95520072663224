%mobile-scroll {
  @include media-breakpoint-down(sm) {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

%ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
