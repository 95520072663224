@use 'sass:math';

.card {
    background: transparent;
    border: var(--width-border) solid var(--content-border-color);

    * {
        border-color: var(--content-border-color);
    }

    @if $enable-drop-shadows {
        box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, $drop-shadows-opacity);
    }

}

.content-card-header,
.content-card-block,
.content-card-footer {
    display: block;
}

.content-card-header {
    position: relative;

    background-color: var(--header-background-color);
    color: var(--header-font-color) !important;
    border-color: var(--header-border-color);

    &:not(:first-child) {
        margin-top: 1px !important;
    }

    .content-card-title {
        margin-bottom: 0;
    }
}

.content-card-block {
    position: relative;
    min-height: 1px;

    background-color: var(--content-background-color);
    color: var(--content-font-color);

    border-color: var(--content-border-color);

    + .content-card-block {
        border-top: var(--width-border) solid var(--content-border-color);
    }

    .content-card-title {
        @extend .h4;
    }

    &:first-child {
        @if $enable-rounded-corners {
            border-top-right-radius: $rounded-corners-radius - $width-border !important;
            border-top-left-radius: $rounded-corners-radius - $width-border !important;
        }
        border-top-left-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - var(--width-border))"), 0px) !important;
        border-top-right-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - var(--width-border))"), 0px) !important;
    }

    &:last-child {
        @if $enable-rounded-corners {
            border-bottom-right-radius: $rounded-corners-radius - $width-border !important;
            border-bottom-left-radius: $rounded-corners-radius - $width-border !important;
        }
        border-bottom-left-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - var(--width-border))"), 0px) !important;
        border-bottom-right-radius: var-if(var(--enable-rounded-corners), unquote("calc(var(--rounded-corners-radius) - var(--width-border))"), 0px) !important;
    }
}

.content-card-footer {
    background-color: var(--footer-background-color);
    color: var(--footer-font-color) !important;
    border-color: var(--footer-border-color);
}

    .content-card-actions {
        padding: 0;
        margin: 0;
        margin-left: auto;

        > li,
        > div {
            display: inline-block;
            font-size: 85%;
            vertical-align: middle;

            margin-left: math.div($padding, 2);
            margin-left: calc(var(--padding) / 2);
        }
    }

    .content-card-accordion {
        kui-card-header {
            cursor: pointer;

            .content-card-actions {
                opacity: .6;
            }

            &.active {

                background-color: var(--accordion-active-background-color);
                color: var(--accordion-active-font-color) !important;
                border-bottom: var(--accordion-border-width) solid var(--accordion-active-border-color);
                

                + kui-card-body {
                    transition: .3s opacity ease-in-out;
                    opacity: 1;
                }

                // &:hover {
                //     background: var-darken(var(--accordion-active-background-color), .1) !important;
                // }
            }

            &:not(.active) {
                display: block;

                background-color: var(--accordion-background-color);
                color: var(--accordion-font-color) !important;
                border-bottom: var(--accordion-border-width) solid var(--accordion-border-color);


                + kui-card-body {
                    height: 0;
                    overflow: hidden;
                    transition: .3s opacity ease-in-out;
                    opacity: 0;
                    padding: 0 var(--padding);
                    display: none !important;
                }

                .content-card-actions i {
                    transform: rotate(180deg);
                }

                &:hover {
                    background: var(--accordion-active-background-color) !important;
                    color: var(--accordion-active-font-color) !important;
                    border-bottom: var(--accordion-border-width) solid var(--accordion-active-border-color);
                }
            }

        }
    }

    .content-card-jumbotron {
        padding: 10px 20px;

        background: var-darken(var(--main-background-color), .03);

        .card-jumbotron-icon,
        .card-jumbotron-text {
            @extend .d-inline-block;
            @extend .align-middle;

            color: var(--main-font-color);
        }

        .card-jumbotron-icon {
            margin-right: math.div($padding, 2);
            margin-right: calc(var(--padding) / 2);

            i {
                font-size: 150%;
            }
        }

        @include media-breakpoint-up(md) {
            .card-jumbotron-icon i {
                font-size: 250%;
            }
        }
    }

.content-card-accordion {
    .content-card-header:last-of-type:not(.active) {
        border-bottom-left-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
        border-bottom-right-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
    }
}

kui-accordion {
    .card {
        border: none;
        box-shadow: none;
    }
    .content-card-accordion {
        kui-card-header {
            border-left: none;
            border-right: none;
        }
    }

    .content-card-header {
        .content-card-title {

            font-family: var(--accordion-font-family);
            font-size: var(--accordion-font-size);
            font-weight: var(--accordion-font-weight);
            text-transform: var(--accordion-font-transform);

        }
    }
}