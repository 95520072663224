//
// css variable text colors
//
@mixin var-darken-text($var, $opacity: .25) {
    background: var-darken($var, $opacity);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
}

@mixin var-lighten-text($var, $opacity: .25) {
    background: var-lighten($var, $opacity);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
}
