//
// alert states to support css variables
//
kui-alert {
    position: relative;
    padding: var(--width-border);
    display: block;

    margin: var-if(var(--enable-rounded-corners), var(--padding), 0px);

    &.no-padding {
        margin: 0;
    }

    &:last-of-type {
        margin-bottom: $padding;
        margin-bottom: var(--padding);
    }

    &:after {
        content: '';
        z-index: map-get($zindex, one);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

}

.alert {

    background-color: transparent; // var(--main-background-color);
    color: var(--main-font-color);
    border-color: var(--main-border-color);


    border-width: var(--width-border);
    margin-bottom: 0;
    border: none;

    hr {
        height: $width-border;
        height: var(--width-border);
        border: none;
        background: rgba(0, 0, 0, .25);
    }

    .alert-text {
        vertical-align: middle;
    }

    .alert-link {
        text-decoration: underline !important;

        &:hover {
            opacity: .7;
        }
    }
}

//
// css varaible replacements
//
@each $color, $value in $theme-colors {

    // kui-alert[colorType=#{$color}] {

    //     &:after {
    //         // this adds a border of the correct theme color
    //         background: var-darken(var(--color-#{$color}-color), .10);
    //         @if $enable-rounded-corners {
    //             border-radius: $rounded-corners-radius;
    //         }
    //         border-radius: var-if(var(--enable-rounded-corners), var(--rounded-corners-radius), 0px);
    //     }
    // }

    .alert-#{$color} {

        // background: var-lighten(var(--color-#{$color}-color), .15);
        z-index: map-get($zindex, two);

        i {
            color: var(--color-#{$color}-color);
        }

        // .alert-text,
        // i:before,
        // span,
        // div {
        //     @include var-darken-text(var(--color-#{$color}-color), .5);
        // }

        div {
            display: block;
        }

        .alert-link {
            // @include var-darken-text(var(--color-#{$color}-color), .55);
            color: var(--color-primary-color);
            cursor: pointer;
        }

        // hr {
        //     background: var-darken(var(--color-#{$color}-color));
        // }
    }

    .alert-info {
        i {
            color: var(--main-font-color);
        }
    }

}


.key-alert-wrapper {
    padding-top: var-if(var(--enable-rounded-corners), var(--width-border), 0px);
    padding-left: var-if(var(--enable-rounded-corners), var(--width-border), 0px);
    padding-right: var-if(var(--enable-rounded-corners), var(--width-border), 0px);

    &:not(:last-of-type) {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: var-if(var(--enable-rounded-corners), var(--padding), 0px);
    }

    &.bordered {
        padding: $width-border;
        padding: var(--width-border);
    }

    .alert-text {
        kui-icon:first-child {
            @extend .pr-3;
        }
    }

    &--stretch {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
}
