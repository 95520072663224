.btn-group .btn {
    margin-left: 1px !important;
}

.btn-group.show .dropdown-toggle {
    // removes the default bootstrap box shadow on activated dropdown buttons
    box-shadow: none;
}

//
// css varaible replacements
//
.btn {
    border-width: var(--width-border);
    cursor: pointer;
    overflow: hidden;
    display: inline-block;

    text-transform: var(--button-font-transform);
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    border-radius: var(--button-border-radius);
    color: var(--main-font-color);

    &-sm {
        font-size: 85%;
    }

    &-lg {
        font-size: 115%;
    }
}

.btn-none {
    border-style: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

@each $color, $value in $theme-colors {

    .btn-#{$color} {
        @include button($color);
    }

    .btn-outline-#{$color} {
        @include button-outline($color);
    }
}
